import { createAction, handleAction, handleActions } from 'redux-actions';
import { put, takeEvery, takeLatest, call } from 'redux-saga/effects';
import produce from 'immer';
import axios from 'axios';

const initialState = {
    noticeList: []
}

const SETNOTICE = "notice/SETNOTICE";
export const setNotice = createAction(SETNOTICE, (input) => input);

const SETNOTICELIST = "notice/SETNOTICELIST";
export const setNoticeList = createAction(SETNOTICELIST, (input) => input);

export function* waitSetNotice() {
    yield takeEvery(SETNOTICE,initSetNotice)
}

function* initSetNotice() {
    const initData = yield call([axios, "post"], "");

    yield put(setNoticeList(initData.data))
}



const ivory = handleActions({
    [SETNOTICELIST]: (state, { payload: input }) =>
        produce(state, (draft)=>{
            draft.noticeList = input
        })

}, initialState);

export default ivory;