import React, { useState } from 'react'
import { Link } from 'react-router-dom';

import menuStyles from "../asset/css/Common.module.css";

const Menu = ({ onClickLi, menuType, setMenuType, detailMenuType, setDetailMenuType }) => {

    return (
        <div className={menuStyles.leftBox}>
            <div className={menuStyles.titWrap}>
                <img src={require("../asset/img/common_logo_symbol@2x.png")} width={"44"} alt="MMT Admin 로고" />
                <h2 className={menuStyles.tit}>MMT Administrator</h2>
            </div>
            <ul className={menuStyles.leftMenu} >
                <li className={`${menuStyles.main} ${menuType == "dashboard" ? menuStyles.on : ""}`} >
                    <Link to={"/"} onClick={() => {
                        onClickLi("dashboard", "")
                    }}>Dashboard</Link>
                </li>

                <li className={`${menuStyles.staking} ${menuType == "mmtstaking" ? menuStyles.on : ""}`} >
                    <Link to={"/"} onClick={() => {
                        onClickLi("mmtstaking", "")
                    }}>MMT Staking</Link>
                    <ul className={`${menuStyles.twoDepth}`} >
                        <li className={`${detailMenuType == "pool" ? menuStyles.on : ""}`} ><Link to={"/mmtStaking/pool"} onClick={() => { setDetailMenuType("pool") }}>Pool 관리</Link></li>
                        <li className={`${detailMenuType == "mmt" ? menuStyles.on : ""}`} ><Link to={"/mmtStaking/mmt"} onClick={() => { setDetailMenuType("mmt") }}>MMT 관리</Link></li>
                        <li className={`${detailMenuType == "operator" ? menuStyles.on : ""}`} ><Link to={"/mmtStaking/operator"} onClick={() => { setDetailMenuType("operator") }}>운영자 관리</Link></li>
                    </ul>
                </li>

                <li className={`${menuStyles.ivoryswap}  ${menuType == "ivoryswap" ? menuStyles.on : ""}`}>
                    <Link to={"/"} onClick={() => {
                        onClickLi("ivoryswap", "");
                    }}
                    >ivoryswap</Link>
                    <ul className={menuStyles.twoDepth} >
                        <li className={`${detailMenuType == "notice" ? menuStyles.on : ""}`}><Link to={"/ivoryswap/notice"} onClick={() => { setDetailMenuType("notice") }}>공지사항 관리</Link></li>
                        <li className={`${detailMenuType == "faq" ? menuStyles.on : ""}`}><Link to={"/ivoryswap/faq"} onClick={() => { setDetailMenuType("faq") }}>FAQ 관리</Link></li>
                    </ul>
                </li>

                <li className={`${menuStyles.edem}  ${menuType == "edem" ? menuStyles.on : ""}`}>
                    <Link to={"/"} onClick={() => {
                        onClickLi("edem", "");
                    }}>EDEM</Link>
                    <ul className={menuStyles.twoDepth}></ul>
                </li>
            </ul>
        </div>
    )
}

export default Menu