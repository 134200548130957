import React, { useEffect, useState } from 'react';
import moment from 'moment';

import MMTStakingJSON from "../abi/MMTStaking.json";
import { BN } from 'bn.js';
import { aprFormula, fromWei, toLocaleStringOption, toWei } from '../common/common';

import poolStyle from "../asset/css/Common.module.css";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/esm/locale";

import "../asset/css/reactDatePicker.css"
import { createMMTPool, updatePool } from '../redux_modules/pool';
import { useDispatch } from 'react-redux';

const MMTStaking_pool = ({ userId, setMenuType, setDetailMenuType, account, active, web3, defaultProviderWeb3 }) => {


  const dispatch = useDispatch();

  const contractAddress = process.env.REACT_APP_STAKING_CONTRACTADDRESS;

  const [pageType, setPageType] = useState("poolList");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [poolList, setPoolList] = useState([]);
  const [poolLength, setPoolLength] = useState();
  const [contract, setContract] = useState();
  const [viewPoolList, setViewPoolList] = useState([]);

  const [totalPageNum, setTotalPageNum] = useState(0);
  const [nowPageNum, setNowPageNum] = useState(1);
  const [limit, setLimit] = useState(5);

  const [updatePoolInfo, setUpdatePoolInfo] = useState();


  const [createPool, setCreatePool] = useState({
    poolName: "",
    startRewardTimeStamp: "",
    endRewardTimeStamp: "",
    rewardMMTperBlock: "",
    minDepositAmount: "",
    commission: "",
    poolOwner: "",
  })
  const [createStartDate, setCreateStartDate] = useState()
  const [createEndDate, setCreateEndDate] = useState();

  const [owner, setOwner] = useState("");

  const [tx, setTx] = useState("");

  const setContractMethods = () => {
    if (defaultProviderWeb3 != undefined) {
      let contract = new defaultProviderWeb3.eth.Contract(MMTStakingJSON, contractAddress);
      setContract(contract)
    }
  }

  const settingOwner = async () => {
    if (contract != undefined) {
      let owner = await contract.methods.owner().call();
      setOwner(owner.toLowerCase());
    }
  }

  const setPoolLists = async () => {
    if (contract != undefined && defaultProviderWeb3 != undefined) {
      try {
        let poolLength = await contract.methods.poolLength().call();

        let setData = []
        for (let i = 0; i < poolLength; i++) {
          let pools = await contract.methods.pools(i).call();

          // 현재 블록
          let blockuNmber = await contract.methods.BlockNumber().call();
          // 현재 블록에 대한 타임 스탬프
          let { timestamp } = await defaultProviderWeb3.eth.getBlock(blockuNmber);

          // startBlock
          let startBlockSubBlock = new BN(`${pools.startRewardBlock}`).sub(new BN(`${blockuNmber}`)).toString();
          let startBlockSubTimeStamp;
          let startRewardTimeStamp;


          if (Number(startBlockSubBlock) >= 0) {
            startBlockSubTimeStamp = new BN(`${Math.abs(startBlockSubBlock)}`).mul(new BN(`${3}`)).toString();
            startRewardTimeStamp = new BN(`${timestamp}`).add(new BN(`${startBlockSubTimeStamp}`)).toString();
          } else {
            startBlockSubTimeStamp = new BN(`${Math.abs(startBlockSubBlock)}`).mul(new BN(`${3}`)).toString();
            startRewardTimeStamp = new BN(`${timestamp}`).sub(new BN(`${startBlockSubTimeStamp}`)).toString();
          }

          //  endBlock
          let endBlockSubBlock = new BN(`${pools.endRewardBlock}`).sub(new BN(`${pools.startRewardBlock}`)).toString();
          let endBlockSubTimeStamp;
          let endRewardTimeStamp;

          if (Number(endBlockSubBlock) >= 0) {
            endBlockSubTimeStamp = new BN(`${Math.abs(endBlockSubBlock)}`).mul(new BN(`${3}`)).toString();
            endRewardTimeStamp = new BN(`${startRewardTimeStamp}`).add(new BN(`${endBlockSubTimeStamp}`)).toString();
          } else {
            endBlockSubTimeStamp = new BN(`${Math.abs(endBlockSubBlock)}`).mul(new BN(`${3}`)).toString();
            endRewardTimeStamp = new BN(`${startRewardTimeStamp}`).sub(new BN(`${endBlockSubTimeStamp}`)).toString();
          }

          let apr = await aprFormula(defaultProviderWeb3, pools.totalRewardbalance, pools.stakedBalance);

          let data = {
            pid: i,
            poolName: pools.poolName,
            startRewardBlock: pools.startRewardBlock,
            endRewardBlock: pools.startRewardBlock,
            rewardMMTperBlock: fromWei(defaultProviderWeb3, pools.rewardMMTperBlock),
            stakedBalance: fromWei(defaultProviderWeb3, pools.stakedBalance),
            totalRewardbalance: fromWei(defaultProviderWeb3, pools.totalRewardbalance),
            lastRewardBlock: pools.lastRewardBlock,
            rewardRate: apr,
            commission: pools.commission,
            startRewardTimeStamp: getNewDate(startRewardTimeStamp),
            endRewardTimeStamp: getNewDate(endRewardTimeStamp),
            poolOwner: pools.poolOwner,
            minDepositAmount: fromWei(defaultProviderWeb3, pools.minDepositAmount),
          }

          setData.push(data);
        }

        setPoolList(setData)
      } catch (error) {
        console.log(error)
      }
    }
  }

  const getNewDate = (timestamp) => {
    let date = new Date(timestamp * 1000);
    let data = moment(date).format("YYYY/MM/DD HH:mm");

    return data;
  }

  const setViewList = async (page) => {
    if (poolList != undefined) {
      let startDateTimeStamp = await changeDate(startDate);
      let endDateTimeStamp = await changeDate(endDate);

      if (isNaN(startDateTimeStamp)) {
        startDateTimeStamp = 0;
      }
      if (isNaN(endDateTimeStamp)) {
        endDateTimeStamp = 9999999999;
      }

      const resultList_1 = [];

      for (let i = 0; i < poolList.length; i++) {
        let poolStartTimestamp = await changeDate(poolList[i].startRewardTimeStamp);
        let poolEndateTimeStamp = await changeDate(poolList[i].endRewardTimeStamp);
        if (poolList[i] == undefined) {
          break;
        } else if (startDateTimeStamp <= poolStartTimestamp && endDateTimeStamp >= poolEndateTimeStamp) {

          resultList_1.push(poolList[i]);
        }
      }


      const resultList_2 = [];
      for (let i = (limit * page) - limit; i < (limit * page); i++) {
        if (resultList_1[i] == undefined) { break; }
        resultList_2.push(resultList_1[i]);
      };
      setPoolLength(resultList_1.length)
      let totalPageNum = Math.ceil(resultList_1.length / limit);
      setTotalPageNum(totalPageNum)
      setViewPoolList(resultList_2)
    }
  }

  const onClickPrevious = (num) => {
    if (Number(num - 1) != 0) {
      setNowPageNum(num - 1);
    }
  }

  const onClickNext = (num) => {
    if (Number(num) != totalPageNum) {
      setNowPageNum(num + 1);
    }
  };


  const pageDiv = () => {
    const result = [];
    for (let i = 0; i < totalPageNum; i++) {
      result.push(<li className={`${nowPageNum == i + 1 ? poolStyle.on : ""}`} key={i} onClick={() => { setNowPageNum(i + 1) }}>{i + 1}</li>)
    }

    return result
  }

  const onClickSetUpdatePool = (pid) => {
    setPageType("poolUpdate");
    setUpdatePoolInfo(poolList[pid])
  };

  const onClickUpdatePool = async (e) => {
    e.preventDefault();
    let result
    if (account == "") {
      window.alert("connect Wallet")
    } else if (updatePoolInfo.poolName == "") {
      window.alert("이름 빈칸")
    } else if (updatePoolInfo.commission == "") {
      window.alert("commission 빈칸")
    } else if (updatePoolInfo.minDepositAmount == "") {
      window.alert("minDepositAmount 빈칸")
    } else if (updatePoolInfo.poolOwner == "") {
      window.alert("poolOwner 빈칸")
    } else if (!updatePoolInfo.poolOwner.includes("0x")) {
      window.alert("poolOwner 0x 아님")
    } else if (updatePoolInfo.poolOwner.length != 42) {
      window.alert("poolOwner 42 글자 아님")
    } else if (owner.toLowerCase().includes(account)) {
      let answer = window.confirm("수정 할 겨?");
      if (answer) {
        // value 퍼블 나온거 맞춰서 넣기
        let pid = updatePoolInfo.pid;
        let poolName = updatePoolInfo.poolName;
        let startRewardTimeStamp = updatePoolInfo.startRewardTimeStamp;
        let endRewardTimeStamp = updatePoolInfo.endRewardTimeStamp;
        let rewardMMTperBlock = toWei(web3, updatePoolInfo.rewardMMTperBlock);
        let commission = updatePoolInfo.commission;
        let poolOwner = updatePoolInfo.poolOwner;
        let minDepositAmount = toWei(web3, updatePoolInfo.minDepositAmount);

        startRewardTimeStamp = setTimeStamp(startRewardTimeStamp);
        endRewardTimeStamp = setTimeStamp(endRewardTimeStamp);

        let startRewardBlock = await getBlockNumber(startRewardTimeStamp);
        let endRewardBlock = await getBlockNumber(endRewardTimeStamp);

        try {
          let contract = new web3.eth.Contract(MMTStakingJSON, contractAddress);
          result = await contract.methods.setPool(
            pid,
            poolName,
            startRewardBlock,
            endRewardBlock,
            rewardMMTperBlock,
            minDepositAmount,
            commission,
            poolOwner
          ).send({
            from: account
          });

          if (result.status) {
            let data = {
              pid,
              poolName,
              startRewardBlock,
              endRewardBlock,
              rewardMMTperBlock,
              minDepositAmount,
              commission,
              poolOwner
            }
            setTx(result.transactionHash)
            setPageType("poolList");
            dispatch(updatePool({ userId, data }))
          }

        } catch (error) {
          console.log(error)
        }
      }
    } else {
      window.alert("owner 아니여")
    }
  }

  const getBlockNumber = async (inputTimestamp) => {
    if (defaultProviderWeb3 != undefined) {
      let nowBlockNumber = await defaultProviderWeb3.eth.getBlockNumber();
      let nowBlockNumberData = await defaultProviderWeb3.eth.getBlock(nowBlockNumber);
      let nowBlcoNumberTimeStamp = nowBlockNumberData.timestamp;

      let subTimeStamp = new BN(`${nowBlcoNumberTimeStamp}`).sub(new BN(`${inputTimestamp}`)).toString();

      let returnData;

      if (subTimeStamp >= 0) {
        let divBlock = new BN(`${Math.abs(subTimeStamp)}`).div(new BN(`${3}`)).toString();
        returnData = new BN(`${nowBlockNumber}`).sub(new BN(`${divBlock}`)).toString();
      } else {
        let divBlock = new BN(`${Math.abs(subTimeStamp)}`).div(new BN(`${3}`)).toString();
        returnData = new BN(`${nowBlockNumber}`).add(new BN(`${divBlock}`)).toString();

      }

      return returnData
    }
  }

  const setTimeStamp = (inputDate) => {
    const date = new Date(`${inputDate}`);

    return Math.floor(date.getTime() / 1000);
  }

  const onChangeUpdateInput = (e, type) => {
    let inputValue = e.target.value;

    if (type == "poolName") {
      setUpdatePoolInfo((preState) => ({
        ...preState,
        poolName: inputValue
      }))
    } else if (type == "commission") {
      let pattern = /^\d{0,10}$/;
      if (pattern.test(inputValue)) {
        if( inputValue >= 0 && inputValue <= 100){
          setUpdatePoolInfo((preState) => ({
            ...preState,
            commission: inputValue
          }))
        }
      }
    } else if (type == "startDate") {
      setUpdatePoolInfo((preState) => ({
        ...preState,
        startRewardTimeStamp: inputValue
      }))
    } else if (type == "endDate") {
      setUpdatePoolInfo((preState) => ({
        ...preState,
        endRewardTimeStamp: inputValue
      }))
    } else if (type == "poolOwner") {
      setUpdatePoolInfo((preState) => ({
        ...preState,
        poolOwner: inputValue
      }))
    } else if (type == "minDepositAmount") {
      let pattern = /^[0-9]*[.,]?[0-9]*$/;
      if (pattern.test(inputValue) == true) {
        let index = inputValue.indexOf(".");
        if (index != -1) {
          let decimalLength = inputValue.substr((index + 1), inputValue.length);
          if (decimalLength.length <= 18) {
            setUpdatePoolInfo((preState) => ({
              ...preState,
              minDepositAmount: inputValue
            }))

          }
        } else {
          setUpdatePoolInfo((preState) => ({
            ...preState,
            minDepositAmount: inputValue
          }))
        }
      }
    }
  }

  const onChangeCreatePool = (e, type) => {
    let value = e.target.value;
    if (type == "rewardMMTperBlock") {
      let pattern = /^[0-9]*[.,]?[0-9]*$/;
      if (pattern.test(value) == true) {
        let index = value.indexOf(".");
        if (index != -1) {
          let decimalLength = value.substr((index + 1), value.length);
          if (decimalLength.length <= 18) {
            setCreatePool((preState) => ({
              ...preState,
              rewardMMTperBlock: value
            }))

          }
        } else {
          setCreatePool((preState) => ({
            ...preState,
            rewardMMTperBlock: value
          }))
        }
      }
    } else if (type == "minDepositAmount") {
      let pattern = /^[0-9]*[.,]?[0-9]*$/;
      if (pattern.test(value) == true) {
        let index = value.indexOf(".");
        if (index != -1) {
          let decimalLength = value.substr((index + 1), value.length);
          if (decimalLength.length <= 18) {
            setCreatePool((preState) => ({
              ...preState,
              minDepositAmount: value
            }))

          }
        } else {
          setCreatePool((preState) => ({
            ...preState,
            minDepositAmount: value
          }))
        }
      }

    } else if (type == "commission") {
      let pattern = /^\d{0,10}$/;
      if (pattern.test(value)) {
        if (value >= 0 && value <= 100) {
          setCreatePool((preState) => ({
            ...preState,
            commission: value
          }))
        }
      }
    } else if (type == "poolOwner") {
      setCreatePool((preState) => ({
        ...preState,
        poolOwner: value
      }))
    }
  }

  const onClickCreatePool = async (e) => {
    e.preventDefault();
    if (account == "") {
      window.alert("connect Wallet")

    } else if (createPool.startRewardTimeStamp == "") {
      window.alert("startRewardTimeStamp 빈 칸")

    } else if (createPool.endRewardTimeStamp == "") {
      window.alert("endRewardTimeStamp 빈 칸")

    } else if (!createPool.poolOwner.includes("0x")) {
      window.alert("poolOwner 0x 아님")

    } else if (createPool.poolOwner.length != 42) {
      window.alert("poolOwner 42 글자 아님")

    } else if (owner.toLowerCase().includes(account)) {
      let answer = window.confirm("생성 할 겨?");
      if (answer) {
        let poolName = createPool.poolName;
        let startRewardTimeStamp = createPool.startRewardTimeStamp;
        let endRewardTimeStamp = createPool.endRewardTimeStamp;
        let rewardMMTperBlock = toWei(web3, createPool.rewardMMTperBlock);
        let minDepositAmount = toWei(web3, createPool.minDepositAmount);
        let commission = createPool.commission;
        let poolOwner = createPool.poolOwner;

        let setStartRewardTimeStamp = setTimeStamp(startRewardTimeStamp);
        let setEndRewardTimeStamp = setTimeStamp(endRewardTimeStamp);

        let startRewardBlock = await getBlockNumber(setStartRewardTimeStamp);
        let endRewardBlock = await getBlockNumber(setEndRewardTimeStamp);

        try {
          let contract = new web3.eth.Contract(MMTStakingJSON, contractAddress);
          let result = await contract.methods.createPool(
            poolName,
            startRewardBlock,
            endRewardBlock,
            rewardMMTperBlock,
            minDepositAmount,
            commission,
            poolOwner
          ).send({
            from: account
          });

          if (result.status) {
            let data = {
              poolName: poolName,
              startRewardBlock: startRewardBlock,
              endRewardBlock: endRewardBlock,
              rewardMMTperBlock: fromWei(web3, rewardMMTperBlock),
              minDepositAmount: fromWei(web3, minDepositAmount),
              commission: commission,
              poolOwner: poolOwner,
            }
            setPageType("poolList");
            setTx(result.transactionHash)
            dispatch(createMMTPool({ userId, data }))
          }

        } catch (error) {
          console.log(error)
        }
      }
    } else {
      window.alert("owner 아님")
    }
  }

  const onChangeSearchDate = (e, type) => {
    let input = e.target.value;

    if (type == "start") {
      setStartDate(input)
    } else if (type == "end") {
      setEndDate(input)
    }
  }

  const changeDate = async (date) => {
    let timestamp = Math.floor(new Date(date) / 1000);
    return timestamp
  }

  const onClickReset = () => {
    setStartDate("")
    setEndDate("")
  }

  const onClickSelectDelete = () => {
    window.alert("이 기능 못씀...")
  };

  const CustomInput = () => {
    
  }

  useEffect(() => {
    setContractMethods();
  }, [defaultProviderWeb3]);

  useEffect(() => {
    setPoolLists();
  }, [contract, defaultProviderWeb3, tx]);

  useEffect(() => {
    setViewList(nowPageNum);
  }, [poolList, nowPageNum, poolList]);

  useEffect(() => {
    let date = Math.floor(new Date());
    date = moment(date).format("YYYY.MM.DD HH:MM");
    setCreateStartDate(date)
    setCreateEndDate(date)
  }, []);

  useEffect(() => {
    setDetailMenuType("pool")
    setMenuType("mmtstaking");
  }, []);

  useEffect(() => {
    if (startDate == "" && endDate == "") {
      setViewList(1)
    }
  }, [startDate, endDate, poolList]);

  useEffect(() => {
    settingOwner();
  }, [contract])


  return (
    <div className={poolStyle.rightBox} >
      <div className={poolStyle.content} >
        <h3 className={poolStyle.tit} >Pool 관리</h3>
        {pageType == "poolList" ?
          <div className={`${poolStyle.wrap} ${poolStyle.searchWrap}`}>
            <div className={poolStyle.tit} >검색</div>
            <div className={poolStyle.dateWrap} >
              <div>
                <DatePicker
                  locale={ko}
                  buttonImageOnly={true}
                  dateFormat="YYYY.MM.DD HH:mm"
                  showOn={"both"}
                  showTimeSelect
                  buttonImage={require("../asset/img/common_ico_schedule.png")}
                  className={`${poolStyle.iptNormal} ${poolStyle.dateSearch}`}
                  value={startDate}
                  onChange={(date) => { setStartDate(moment(date).format("YYYY.MM.DD HH:mm")) }
                  } />
              </div>
              <span>~</span>
              <div>
                <DatePicker
                  locale={ko}
                  buttonImageOnly={true}
                  showOn={"both"}
                  dateFormat="YYYY.MM.DD HH:mm"
                  showTimeSelect
                  className={`${poolStyle.iptNormal} ${poolStyle.dateSearch2}`}
                  value={endDate}
                  onChange={(date) => { setEndDate(moment(date).format("YYYY.MM.DD HH:mm")) }}
                />
              </div>
            </div>
            <div className={poolStyle.btnWrap}>
              <button type="button" className={`${poolStyle.btnGrey} ${poolStyle.btnReset}`} onClick={onClickReset}>초기화</button>
              <button type="button" className={`${poolStyle.btnBlack} ${poolStyle.btnSearch}`} onClick={() => { setViewList(1) }}>조회</button>
            </div>
          </div>
          : ""}
        {pageType == "poolList" ?
          <div className={`${poolStyle.wrap} ${poolStyle.tableWrap}`}>
            <div className={poolStyle.numberWrap} >
              <p className={poolStyle.allInfo}>총 {poolLength}건</p>
            </div>
            <table className={poolStyle.tableNormal}>
              <colgroup>
                <col width="50" />
                <col width="50" />
                <col width="200" />
                <col width="100" />
                <col width="150" />
                <col width="230" />
                <col width="120" />
                <col width="100" />
                <col width="300" />
                <col width="80" />
              </colgroup>
              <thead>
                <tr>
                  <th>
                    <label className={poolStyle.chkLabelWrap}>
                      <input type="checkbox" />
                      <span className={poolStyle.chkLabel} ></span>
                    </label>
                  </th>
                  <th>Pid</th>
                  <th>Pool Name</th>
                  <th>APR</th>
                  <th>Total Staked Balance</th>
                  <th>Start - End date</th>
                  <th>MMT per block</th>
                  <th>Commission</th>
                  <th>Pool Owner</th>
                  <th>관리</th>
                </tr>
              </thead>
              <tbody>
                {viewPoolList.map((item, index) => (
                  <tr key={item.pid}>
                    <td>
                      <label className={poolStyle.chkLabelWrap} >
                        <input type="checkbox" />
                        <span className={poolStyle.chkLabel} ></span>
                      </label>
                    </td>
                    <td>{item.pid}</td>
                    <td>{item.poolName}</td>
                    <td>{toLocaleStringOption(item.rewardRate, 2)}</td>
                    <td>{toLocaleStringOption(item.stakedBalance, 4)}</td>
                    <td>{item.startRewardTimeStamp} ~ {item.endRewardTimeStamp}</td>
                    <td>{toLocaleStringOption(item.rewardMMTperBlock, 4)}</td>
                    <td>{item.commission}</td>
                    <td>{item.poolOwner}</td>
                    <td><div onClick={() => onClickSetUpdatePool(item.pid)} className={poolStyle.management}>수정</div></td>
                  </tr>
                ))}
              </tbody>
            </table>
            <button className={`${poolStyle.btnGrey} ${poolStyle.btnDelete}`} onClick={onClickSelectDelete}>선택삭제</button>
            <div className={poolStyle.paging}>
              <ul>
                <li className={poolStyle.prev} onClick={() => { onClickPrevious(nowPageNum) }}><div>prev</div></li>
                {pageDiv()}
                <li className={poolStyle.next} onClick={() => { onClickNext(nowPageNum) }}><div>next</div></li>
              </ul>
            </div>
            <button className={`${poolStyle.btnGreen} ${poolStyle.btnWrite}`} onClick={() => { setPageType("poolCreate") }}><>등록</></button>
          </div>
          :
          pageType == "poolCreate" ?
            <div className={`${poolStyle.wrap} ${poolStyle.tableWrap}`}>
              <div className={poolStyle.tit}>Pool 등록</div>
              <form onSubmit={onClickCreatePool}>
                <table className={`${poolStyle.tableNormal} ${poolStyle.write}`}>
                  <colgroup>
                    <col width="200" />
                    <col width="*" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>Pool Name</th>
                      <td>
                        <input type="text"
                          className={poolStyle.iptNormal}
                          value={createPool.poolName}
                          required
                          onChange={(e) => {
                            setCreatePool((prevData) => ({
                              ...prevData,
                              poolName: e.target.value
                            }))
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Start - End date</th>
                      <td>
                        <div className={poolStyle.dateWrap}>
                          <div>
                            <DatePicker
                              locale={ko}
                              buttonImageOnly={true}
                              showOn={"both"}
                              dateFormat="YYYY.MM.DD HH:mm"
                              buttonImage={require("../asset/img/common_ico_schedule.png")}
                              className={`${poolStyle.iptNormal} ${poolStyle.dateSearch}`}
                              showTimeSelect
                              value={createPool.startRewardTimeStamp}
                              onChange={(date) => {
                                setCreatePool((prevData) => ({
                                  ...prevData,
                                  startRewardTimeStamp: moment(date).format("YYYY.MM.DD HH:mm")
                                }))
                              }}
                            />
                          </div>
                          <span>~</span>
                          <div>
                            <DatePicker
                              locale={ko}
                              buttonImageOnly={true}
                              showOn={"both"}
                              dateFormat="YYYY.MM.DD HH:mm"
                              buttonImage={require("../asset/img/common_ico_schedule.png")}
                              className={`${poolStyle.iptNormal} ${poolStyle.dateSearch}`}
                              showTimeSelect
                              value={createPool.endRewardTimeStamp}
                              onChange={(date) => {
                                setCreatePool((prevData) => ({
                                  ...prevData,
                                  endRewardTimeStamp: moment(date).format("YYYY.MM.DD HH:mm")
                                }))
                              }}
                            />
                          </div>
                          <span>ex) 2022.09.02 15:01</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>MMT per block</th>
                      <td>
                        <input type="text" className={poolStyle.iptNormal}
                          required
                          placeholder='소수점 18자리 초과 X'
                          value={createPool.rewardMMTperBlock}
                          onChange={(e) => { onChangeCreatePool(e, "rewardMMTperBlock") }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>MinDepositAmount</th>
                      <td>
                        <input type="text" className={poolStyle.iptNormal} required
                          placeholder='소수점 18자리 초과 X'
                          value={createPool.minDepositAmount}
                          onChange={(e) => { onChangeCreatePool(e, "minDepositAmount") }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Commission</th>
                      <td>
                        <input type="number" className={poolStyle.iptNormal} required pattern={"[0-9]+"}
                          placeholder='0~100 사이(소수점 입력 X)'
                          value={createPool.commission}
                          onChange={(e) => { onChangeCreatePool(e, "commission") }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Pool Owner</th>
                      <td>
                        <input type="text" className={poolStyle.iptNormal}
                          required placeholder='0x....'
                          value={createPool.poolOwner}
                          onChange={(e) => { onChangeCreatePool(e, "poolOwner") }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className={poolStyle.btnModifyWrap} >
                  <button type="button" className={poolStyle.btnGrey}>
                    <div onClick={() => { setPageType("poolList") }}>취소</div>
                  </button>
                  <button type={"submit"} className={`${poolStyle.btnGreen} ${poolStyle.btnSave}`}>등록</button>
                </div>
              </form>
            </div>
            : pageType == "poolUpdate" ?
              <div className={`${poolStyle.wrap} ${poolStyle.tableWrap}`}>
                <div className={poolStyle.tit} >Pool 수정</div>
                <form onSubmit={onClickUpdatePool}>
                  <table className={`${poolStyle.tableNormal} ${poolStyle.write}`}>
                    <colgroup>
                      <col width="200" />
                      <col width="*" />
                    </colgroup>
                    <tbody>
                      <tr>
                        <th>Pool Name</th>
                        <td>
                          {/* <div className={poolStyle.value}>{updatePoolInfo.poolName}</div> */}
                          <input type="text" onChange={(e) => { onChangeUpdateInput(e, "poolName") }} className={`${poolStyle.iptNormal} ${poolStyle.dateSearch}`} value={updatePoolInfo.poolName} />
                        </td>
                      </tr>
                      <tr>
                        <th>APR</th>
                        <td>
                          <div className={poolStyle.value}>{toLocaleStringOption(updatePoolInfo.rewardRate, 2)}</div>
                        </td>
                      </tr>
                      <tr>
                        <th>Total Staked Balance</th>
                        <td>
                          <div className={poolStyle.value}>{toLocaleStringOption(updatePoolInfo.stakedBalance, 2)}</div>
                        </td>
                      </tr>
                      <tr>
                        <th>Start - End date</th>
                        <td>
                          <div className={poolStyle.dateWrap}>
                            <div>
                              <DatePicker
                                locale={ko}
                                buttonImageOnly={true}
                                showOn={"both"}
                                dateFormat="YYYY.MM.DD HH:mm"
                                buttonImage={require("../asset/img/common_ico_schedule.png")}
                                className={`${poolStyle.iptNormal} ${poolStyle.dateSearch}`}
                                showTimeSelect
                                value={updatePoolInfo.startRewardTimeStamp}
                                onChange={(date) => {
                                  setUpdatePoolInfo((preState) => ({
                                    ...preState,
                                    startRewardTimeStamp: moment(date).format("YYYY.MM.DD HH:mm")
                                  }))
                                }}
                              />
                            </div>
                            <span>~</span>
                            <div>
                              <DatePicker
                                locale={ko}
                                buttonImageOnly={true}
                                showOn={"both"}
                                dateFormat="YYYY.MM.DD HH:mm"
                                buttonImage={require("../asset/img/common_ico_schedule.png")}
                                className={`${poolStyle.iptNormal} ${poolStyle.dateSearch}`}
                                showTimeSelect
                                value={updatePoolInfo.endRewardTimeStamp}
                                onChange={(date) => {
                                  setUpdatePoolInfo((preState) => ({
                                    ...preState,
                                    endRewardTimeStamp: moment(date).format("YYYY.MM.DD HH:mm")
                                  }))
                                }}
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>MMT per block</th>
                        <td>
                          <div className={poolStyle.value}>{toLocaleStringOption(updatePoolInfo.rewardMMTperBlock, 2)}</div>
                        </td>
                      </tr>
                      <tr>
                        <th>Commission</th>
                        <td>
                          {/* <div className={poolStyle.value}>{updatePoolInfo.commission}</div> */}
                          <input type="text" className={`${poolStyle.iptNormal} ${poolStyle.dateSearch}`} value={updatePoolInfo.commission} onChange={(e) => { onChangeUpdateInput(e, "commission") }} />
                        </td>
                      </tr>
                      <tr>
                        <th>Pool Owner</th>
                        <td>
                          {/* <div className={poolStyle.value}>{updatePoolInfo.poolOwner}</div> */}
                          <input type="text" className={`${poolStyle.iptNormal} ${poolStyle.dateSearch}`} value={updatePoolInfo.poolOwner} onChange={(e) => { onChangeUpdateInput(e, "poolOwner") }} />
                        </td>
                      </tr>
                      <tr>
                        <th>MinDepositAmount</th>
                        <td>
                          <input type="text" className={`${poolStyle.iptNormal} ${poolStyle.dateSearch}`} value={updatePoolInfo.minDepositAmount} onChange={(e) => { onChangeUpdateInput(e, "minDepositAmount") }} />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className={poolStyle.btnModifyWrap}>
                    <button type="button" className={poolStyle.btnGrey}>
                      <div onClick={() => { setPageType("poolList") }} >취소</div>
                    </button>
                    <button type="submit" className={`${poolStyle.btnGreen} ${poolStyle.btnSave}`}>저장</button>
                  </div>
                </form>
              </div>
              : ""}
      </div>
    </div>
  )
}

export default MMTStaking_pool