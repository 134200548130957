import { combineReducers } from "redux";
import { all } from "@redux-saga/core/effects";
import { persistReducer } from 'redux-persist';
import storage from "redux-persist/lib/storage";

import user, { waitLogin } from "./user";
import mmt, { waitChangeOwner, waitPlayAction, waitSetLockedBlock } from "./mmt";
import ivory, { waitSetNotice } from "./ivory";
import pool, { waitCreatePool, waitUpdatePool } from "./pool";
import operator, { waitOperatorList, waitSetOperatorStatus } from "./operator";


const persistConfig = {
    key: "mammothAdmin",
    storage,
    whitelist: [
    ]
}

const rootreducer = combineReducers({
    user,
    ivory,
    pool,
    operator,
    mmt,
});

export function* rootsaga() {
    yield all([
        waitLogin(),
        // waitSetNotice(),
        waitOperatorList(),
        waitSetOperatorStatus(),
        waitCreatePool(),
        waitUpdatePool(),
        waitPlayAction(),
        waitChangeOwner(),
        waitSetLockedBlock(),
    ]);
}

const persistedReducer = persistReducer(persistConfig, rootreducer);

export default persistedReducer;