import { createAction, handleAction, handleActions } from 'redux-actions';
import { put, takeEvery, takeLatest, call } from 'redux-saga/effects';
import produce from 'immer';
import axios from 'axios';

const initialState = {

}

const PLAYACTION = "mmt/PLAYACTION";
export const playAction = createAction(PLAYACTION, (input) => input);

const CHANGEOWNER = "mmt/CHANGEOWNER";
export const changeOwner = createAction(CHANGEOWNER, (input) => input);

const SETLOCKEDBLOCKS = "mmt/SETLOCKEDBLOCKS";
export const setLockedBlock = createAction(SETLOCKEDBLOCKS, (input) => input);

export function* waitPlayAction() {
    yield takeEvery(PLAYACTION, playActions)
}

function* playActions(body) {
    let sendData = {
        id: body.payload.userId,
        howMuch: body.payload.data.howMuch,
        add: body.payload.data.address,
    }

    let initDataType = body.payload.type == "deposit" ? "deposit" : "withdraw";
    let initData;

    if (initDataType == "deposit") {
        initData = yield call([axios, "post"], "/mmtstaking/depositMMT", sendData);

    } else if (initDataType == "withdraw") {
        initData = yield call([axios, "post"], "/mmtstaking/wtihdrawMMT", sendData);

    }

    if (initData.status == 200) {

        if (initDataType == "deposit") {
            window.alert("입금 완료")

        } else if (initDataType == "withdraw") {
            window.alert("출금 완료")

        }
    }
}


export function* waitChangeOwner() {
    yield takeEvery(CHANGEOWNER, dbChangeOwner)
}

function* dbChangeOwner(body) {
    let sendData = {
        existingOwner: body.payload.existingOwner,
        newOwner: body.payload.newOwner,
        id: body.payload.userId,
    }

    let initData = yield call([axios, "post"], "/mmtstaking/transferOwnership", sendData);

    if (initData.status == 200) {
        window.alert("owner 변경")
    }
}


export function* waitSetLockedBlock() {
    yield takeEvery(SETLOCKEDBLOCKS, dbSetLockedBlock)
}

function* dbSetLockedBlock(body) {
    let sendData = {
        id: body.payload.userId,
        num: body.payload.time,
        type: body.payload.type
    }

    let initData = yield call([axios, "post"], "/mmtstaking/setLockedBlock", sendData);

    if (initData.status == 200) {
        window.alert("lockup 변경")
    }
}
const mmt = handleActions({

}, initialState);

export default mmt;