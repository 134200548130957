import { createAction, handleAction, handleActions } from 'redux-actions';
import { put, takeEvery, takeLatest, call } from 'redux-saga/effects';
import produce from 'immer';
import axios from 'axios';

const initialState = {
    pool : [],
}

const CREATEPOOL = "pool/CREATEPOOL";
export const createMMTPool = createAction(CREATEPOOL, (input)=>input);

export function* waitCreatePool(){
    yield takeEvery(CREATEPOOL,dbCreatePool)
}

function* dbCreatePool(body){
    
    let sendData = {
        name : body.payload.data.poolName,
        startRewardBlock : body.payload.data.startRewardBlock,
        endRewardBlock : body.payload.data.endRewardBlock,
        rewardMMTperBlock : body.payload.data.rewardMMTperBlock,
        minDepositAmount : body.payload.data.minDepositAmount,
        commission : body.payload.data.commission,
        poolOwner : body.payload.data.poolOwner,
        id:body.payload.userId
    }

    const initData = yield call([axios, "post"],"/mmtstaking/addPool",sendData);

    if(initData.status == 200){
        window.alert("풀 생성 완료")
    }

}

const UPDATEPOOL = "pool/UPDATEPOOL";
export const updatePool = createAction(UPDATEPOOL, (input) => input);

export function* waitUpdatePool() {
    yield takeEvery(UPDATEPOOL, dbUpdatePool);
}

function* dbUpdatePool(body){
    let sendData = {
        name : body.payload.data.poolName,
        startRewardBlock : body.payload.data.startRewardBlock,
        endRewardBlock : body.payload.data.endRewardBlock,
        rewardMMTperBlock : body.payload.data.rewardMMTperBlock,
        minDepositAmount : body.payload.data.minDepositAmount,
        commission : body.payload.data.commission,
        poolOwner : body.payload.data.poolOwner,
        pid : body.payload.data.pid,        
        id:body.payload.userId
    }


    const initData = yield call([axios, "post"],"/mmtstaking/setPool",sendData);

    if(initData.status == 200){
        window.alert("풀 수정 완료")
    }
}


const pool = handleActions({

}, initialState);

export default pool;