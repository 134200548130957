import { createAction, handleAction, handleActions } from 'redux-actions';
import { put, takeEvery, takeLatest, call } from 'redux-saga/effects';
import produce from 'immer';
import axios from 'axios';
import { Cookies } from 'react-cookie';

const initialState = {
    userId :"",
    loginStatus: false,
    account: "",
    active: false,
    chainId: 0
}

const cookies = new Cookies();

// login
const LOGIN = "user/LOGIN";
export const login = createAction(LOGIN, (input) => input);

const SETLOGINSTATUS = "user/SETLOGINSTATUS";
export const setLoginStatus = createAction(SETLOGINSTATUS, (input) => input);

// metamask
const SETACCOUNT = "user/SETACCOUNT";
export const setAccount = createAction(SETACCOUNT, (input) => input);

const SETACTIVE = "user/SETACTIVE";
export const setActive = createAction(SETACTIVE, (input) => input);

const SETCHAINID = "user/SETCHAINID";
export const setChainId = createAction(SETCHAINID, (input) => input);

export function* waitLogin() {
    yield takeEvery(LOGIN, loginUser);
}

function* loginUser(body) {

    let sendData = {
        id: body.payload.id,
        pw: body.payload.password
    }

    try {
        const initData = yield call([axios, "post"], "/users/login", sendData);
        if (initData.status == 200) {
            let setData = {
                status: true,
                id: initData.data.id,
                edem: initData.data.edem,
                ivoryswap: initData.data.ivoryswap,
                mmtstaking: initData.data.mmtstaking,
            }
            yield put(setLoginStatus({id:initData.data.id,status:true}));
            if (body.payload.rememberCheck) {
                window.localStorage.setItem("MammothAdmin", JSON.stringify({
                    loginInfo: setData
                }));
            } else {
                cookies.set("loginStatus", JSON.stringify({
                    loginInfo: setData
                }))
            }
        } else {
            yield put(setLoginStatus({id:"",status:false}));
        }
    } catch (error) {
        if(error.response.status == 401){
            window.alert("로그인 실패")
        }
    }

}

const user = handleActions({
    [SETLOGINSTATUS]: (state, { payload: input }) =>
        produce(state, (draft) => {
            draft.userId = input.id
            draft.loginStatus = input.status
        }),
    [SETACCOUNT]: (state, { payload: input }) =>
        produce(state, (draft) => {
            draft.account = input;
        }),
    [SETACTIVE]: (state, { payload: input }) =>
        produce(state, (draft) => {
            draft.active = input;
        }),
    [SETCHAINID]: (state, { payload: input }) =>
        produce(state, (draft) => {
            draft.chainId = input;
        }),


}, initialState);

export default user;