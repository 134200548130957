import React, { useEffect, useState, } from 'react';
import rightBoxStyle from "../asset/css/Common.module.css";


import MMTStakingJSON from "../abi/MMTStaking.json";
import { BN } from 'bn.js';
import { useDispatch, useSelector } from 'react-redux';
import { setOperatorStatus, waitOperatorLists } from '../redux_modules/operator';

const MMTStaking_operator = ({ userId, setMenuType, setDetailMenuType, account, active, web3, defaultProviderWeb3 }) => {

  const dispatch = useDispatch();

  const [pageType, setPageType] = useState("operatorList");
  const contractAddress = process.env.REACT_APP_STAKING_CONTRACTADDRESS;

  const [contract, setContract] = useState();
  const [viewOperatorList, setViewOperatorList] = useState([]);
  const [searchText, setSearchText] = useState("");

  const [totalPageNum, setTotalPageNum] = useState(0);
  const [nowPageNum, setNowPageNum] = useState(1);
  const [limit, setLimit] = useState(10);

  const operatorLists = useSelector((state) => state.operator.operatorList);

  const [updateOperator, setUpdateOperator] = useState();
  
  const [tx, setTx] = useState("");

  const [createOperatorAddress, setCreateOperatorAddress] = useState("");
  const [createOperatorNickname, setCreateOperatorNickname] = useState("");

  
  const [owner, setOwner] = useState("");

  const setContractMethods = () => {
    if (defaultProviderWeb3 != undefined) {
      let contract = new defaultProviderWeb3.eth.Contract(MMTStakingJSON, contractAddress);
      setContract(contract)
    }
  }

  const setOperatorLists = async () => {
    if (contract != undefined && defaultProviderWeb3 != undefined) {
      dispatch(waitOperatorLists())
    }
  }

  const settingOwner = async () => {
    if(contract !=undefined){
      let owner = await contract.methods.owner().call();
      setOwner(owner.toLowerCase());
    }
  }

  const setViewList = (page) => {

    let result_1 = [];
    
    if (searchText.length == 42) {
      for (let i = 0; i < operatorLists.length; i++) {
        let text = searchText.toLowerCase();
        let addressText = operatorLists[i].address.toLowerCase();
        if (text.includes(addressText)) {
          result_1.push(operatorLists[i])
        }
      }
    }else if(searchText == ""){
      result_1 = operatorLists;
    }

    let resultList_2 = [];
    for (let i = (limit * page) - limit; i < (limit * page); i++) {
      if (result_1[i] == undefined) { break; }
      resultList_2.push(result_1[i]);
    };
    
    let totalPageNum = Math.ceil(result_1.length / limit);
    setTotalPageNum(totalPageNum)
    setViewOperatorList(resultList_2)

  }

  const onChangeSearchText = (e) => {
    setSearchText(e.target.value)
  }

  const setPage = () => {
    const result = [];
    for (let i = 0; i < totalPageNum; i++) {
      result.push(<li className={`${nowPageNum == i + 1 ? rightBoxStyle.on : ""}`} key={i} onClick={() => { setNowPageNum(i + 1) }}>{i + 1}</li>)
    }

    return result
  }

  const onChangeUpdateStatus = (bool, type) => {
    if(type == "bool"){
      let value = bool;
      setUpdateOperator((preState)=>({
        ...preState,
        bool:value?0:1
      }))
    }
  }

  const onChangeNickName = (e) =>{
    let name = e.target.value
    setUpdateOperator((preState)=>({
      ...preState,
      nickname:name
    }))
  }

  const onClicksetOperator = async () =>{
    let result ;
    if(account ==""){
      window.alert("지갑 연결")
    }
    else if(owner.toLowerCase().includes(account)){
      let operatorStatus = await contract.methods.isOperator(updateOperator.address).call({
        from:account
      });
      
      let web3Contract = new web3.eth.Contract(MMTStakingJSON, contractAddress);
      operatorStatus = operatorStatus ? 0 : 1;

      console.log(operatorStatus,updateOperator)

      let changeStatus = false;

      if(updateOperator.bool == 0){
        if(operatorStatus != updateOperator.bool){
          changeStatus=true;
          result = await web3Contract.methods.appointOperator(updateOperator.address).send({
            from:account
          })
        }
      }else if(updateOperator.bool == 1){
        if(operatorStatus != updateOperator.bool){
          changeStatus=true;
          result = await web3Contract.methods.dismissOperator(updateOperator.address).send({
            from:account
          })
        }
      }
      console.log("changeStatus",changeStatus)
      
      if(changeStatus == false){
        setPageType("operatorList")
        setTx("1")
        dispatch(setOperatorStatus({updateOperator,userId,changeStatus}))
      }else{
        if(result.status){
          setPageType("operatorList")
          setTx(result.transactionHash)
          dispatch(setOperatorStatus({updateOperator,userId,changeStatus}))
        }
      }
    }else{
      window.alert("owner 아니유")
    }
  }

  const onClickSetAppointOperator = async () => {
    let web3Contract = new web3.eth.Contract(MMTStakingJSON, contractAddress);
    let result;
    if (account == "") {
      window.alert("지갑 연결")
    } 
    else{
    // else if (owner.toLowerCase().includes(account)) {

    try {
      if (createOperatorAddress.length == 42) {

        result = await web3Contract.methods.appointOperator(createOperatorAddress).send({
          from: account
        })
        let data = {
          NO: "",
          address: createOperatorAddress,
          bool: 0,
          nickname: createOperatorNickname
        }
        if (result.status) {
          setPageType("operatorList")
          setTx(result.transactionHash)
          dispatch(setOperatorStatus({updateOperator:data,userId}))
        }

      } else if (createOperatorAddress.length != 42) {
        window.alert("42글자")
      }
    } catch (error) {
      console.log(error)
    }
    }
    // else{
    //   window.alert("owner 아니유")
    // }
  }

  const onClickPrevious = (num) => {
    if (Number(num - 1) != 0) {
      setNowPageNum(num - 1);
    }
  }

  const onClickNext = (num) => {
    if (Number(num) != totalPageNum) {
      setNowPageNum(num + 1);
    }
  };



  useEffect(() => {
    setMenuType("mmtstaking");
    setDetailMenuType("operator");
  }, []);

  useEffect(() => {
    setContractMethods();
  }, [defaultProviderWeb3]);

  useEffect(() => {
    if (operatorLists != undefined) {
      setViewList(nowPageNum);
    }
  }, [operatorLists, nowPageNum, searchText]);


  useEffect(() => {
    setOperatorLists();
  }, [contract, defaultProviderWeb3, tx]);


  useEffect(()=>{
    settingOwner();
  },[contract])

  const onClickSelectDelete = () => {
    window.alert("이 기능 못씀...")
  }

  return (
    <div className={rightBoxStyle.rightBox}>
      <div className={rightBoxStyle.content}>
        <h3 className={rightBoxStyle.tit}>운영자 관리</h3>
        {
          pageType == "operatorList" ?
            <div className={`${rightBoxStyle.wrap} ${rightBoxStyle.searchWrap} ${rightBoxStyle.faq}`}>
              <div className={rightBoxStyle.tit} >검색</div>
              <div>
                <input type="text" className={`${rightBoxStyle.iptNormal} ${rightBoxStyle.v2}`} placeholder="지갑주소 입력 ex)0x....."
                  onChange={(e) => { onChangeSearchText(e) }}
                  value={searchText}
                />
              </div>
              <button type="button" className={`${rightBoxStyle.btnGrey} ${rightBoxStyle.btnReset}`} onClick={() => { setSearchText(""); }}>초기화</button>
              <button type="button" className={`${rightBoxStyle.btnBlack} ${rightBoxStyle.btnSearch}`} onClick={()=>{
                if(searchText.length == 42){
                  setViewList(1)
                }else{
                  window.alert("42 글자")
                }
              }}>조회</button>
            </div>
            : ""
        }
        {
          pageType == "operatorList" ?
            <div className={`${rightBoxStyle.wrap} ${rightBoxStyle.tableWrap}`}>
              <div className={rightBoxStyle.tit} >운영자 설정</div>
              <table className={rightBoxStyle.tableNormal}  >
                <colgroup>
                  <col width="56" />
                  <col width="106" />
                  <col width="*" />
                  <col width="250" />
                  <col width="116" />
                  <col width="116" />
                </colgroup>
                <thead>
                  <tr>
                    <th>
                      <label className={rightBoxStyle.chkLabelWrap} >
                        <input type="checkbox" />
                        <span className={rightBoxStyle.chkLabel}></span>
                      </label>
                    </th>
                    <th>NO</th>
                    <th>지갑 주소</th>
                    <th>운영자 닉네임</th>
                    <th>상태</th>
                    <th>관리</th>
                  </tr>
                </thead>
                <tbody>
                  {viewOperatorList.map((item, index) => (
                    <tr key={item.NO}>
                      <td>
                        <label className={rightBoxStyle.chkLabelWrap}>
                          <input type="checkbox" />
                          <span className={rightBoxStyle.chkLabel}></span>
                        </label>
                      </td>
                      <td>{item.NO}</td>
                      <td>{item.address}</td>
                      <td>{item.nickname}</td>
                      <td>{item.bool == 0?"활성화":"비활성화"}</td>
                      <td>
                        <div className={rightBoxStyle.management} onClick={() => {
                          setPageType("operatorUpdate")
                          setUpdateOperator(item)
                        }}>수정</div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <button className={`${rightBoxStyle.btnGrey} ${rightBoxStyle.btnDelete}`} onClick={onClickSelectDelete}>선택삭제</button>
              <div className={rightBoxStyle.paging}>
                <ul>
                  <li className={rightBoxStyle.prev}><div onClick={() => { onClickPrevious(nowPageNum) }}>prev</div></li>
                  {setPage()}
                  <li className={rightBoxStyle.next}><div onClick={() => { onClickNext(nowPageNum) }}>next</div></li>
                </ul>
              </div>
              <button className={`${rightBoxStyle.btnGreen} ${rightBoxStyle.btnWrite}`} onClick={() => { setPageType("operatorCreate") }}><div>등록</div></button>
            </div>
            : pageType == "operatorCreate" ?
              <div className={`${rightBoxStyle.wrap} ${rightBoxStyle.tableWrap}`}>
                <div className={rightBoxStyle.tit}>운영자 등록</div>
                <table className={rightBoxStyle.tableNormal}>
                  <colgroup>
                    <col width={"*"} />
                    <col width={"250"} />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>지갑 주소 상세정보</th>
                      <th>운영자 닉네임</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <input type={"text"} placeholder="0x...." className={`${rightBoxStyle.iptNormal} ${rightBoxStyle.modify}`} value={createOperatorAddress} onChange={(e)=>{setCreateOperatorAddress(e.target.value)}}/>
                      </td>
                      <td>
                        <input type={"text"} className={`${rightBoxStyle.iptNormal} ${rightBoxStyle.modify}`} value={createOperatorNickname} onChange={(e)=>{setCreateOperatorNickname(e.target.value)}}/>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className={rightBoxStyle.btnModifyWrap} >
                  <span className={rightBoxStyle.warningTxt}>※ 운영자는 락업 기간이 없기 때문에 신중히 등록 해주십시오.</span>
                  <button type="button" className={rightBoxStyle.btnGrey} >
                    <div onClick={() => { setPageType("operatorList") }}>취소</div>
                  </button>
                  <button type="button" className={`${rightBoxStyle.btnGreen} ${rightBoxStyle.btnSave}`} onClick={onClickSetAppointOperator}>저장</button>
                </div>
              </div>
              : pageType == "operatorUpdate" ?
                <div className={`${rightBoxStyle.wrap} ${rightBoxStyle.tableWrap}`} >
                  <div className={rightBoxStyle.tit}>운영자 정보 수정</div>
                  <table className={rightBoxStyle.tableNormal}>
                    <colgroup>
                      <col width="*" />
                      <col width="250" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>지갑 주소 상세정보</th>
                        <th>활성화 / 비활성화</th>
                        <th>운영자 닉네임</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{updateOperator.address}</td>
                        <td>
                          <label htmlFor="show" onClick={(e)=>{onChangeUpdateStatus(true,"bool")}}>
                            <input type="radio" id={rightBoxStyle.show} name="viewType" checked={updateOperator.bool == 0 ? true:false} readOnly/>
                            <span className={`${rightBoxStyle.radioLabel}`}></span>
                            <span className={`${rightBoxStyle.radioTxt}`}>활성화</span>
                          </label>
                          <label htmlFor="hide"  onClick={(e)=>{onChangeUpdateStatus(false,"bool")}}>
                            <input type="radio" id={rightBoxStyle.hide} name="viewType" checked={updateOperator.bool == 1 ? true:false} readOnly/>
                            <span className={`${rightBoxStyle.radioLabel}`}></span>
                            <span className={`${rightBoxStyle.radioTxt}`}>비활성화</span>
                          </label>
                        </td>
                        <td>
                          <input type="text" className={`${rightBoxStyle.iptNormal} ${rightBoxStyle.modify}`} value={updateOperator.nickname} onChange={(e)=>{
                            onChangeNickName(e)
                          }}/>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className={rightBoxStyle.btnModifyWrap}>
                    <button type="button" className={rightBoxStyle.btnGrey} >
                      <div onClick={() => { setPageType("operatorList") }}>취소</div>
                    </button>
                    <button type="button" className={`${rightBoxStyle.btnGreen} ${rightBoxStyle.btnSave}`} onClick={onClicksetOperator}>저장</button>
                  </div>
                </div> : ""
        }

      </div>
    </div>
  )
}

export default MMTStaking_operator