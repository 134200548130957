import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { login, setLoginStatus } from '../redux_modules/user';

import loginStyles from "../asset/css/Common.module.css";

const Login = ({ loginStatus, cookies, }) => {

    const dispatch = useDispatch();

    const [id, setId] = useState("");
    const [password, setPassword] = useState("");
    const [rememberCheck, setRememberCheck] = useState(false);


    const onClickLoingBtn = () => {
        if (id == "" || password == "") {
            let message = id == "" ? "아이디" : "비밀 번호";
            window.alert(message + "입력 해주세요")

        } else {
            let data = {
                id,
                password,
                rememberCheck
            }
            dispatch(login(data))
        }

    }

    const onClickRememberCheck = () => {
        setRememberCheck(!rememberCheck)
    }

    const checkRememberCheck = () => {
        let loginStatus = window.localStorage.getItem("MammothAdmin");
        loginStatus = JSON.parse(loginStatus)
        if (loginStatus == null || loginStatus == false) {
            dispatch(setLoginStatus({ id: "", status: false }))
            return;
        } else if (loginStatus.loginInfo.status == true) {
            dispatch(setLoginStatus({ id: loginStatus.loginInfo.id, status: true }))
        }
    }

    const checkCookies = () => {
        let cookieLoginStatus = cookies.loginStatus;
        if (cookieLoginStatus == undefined) {
            dispatch(setLoginStatus({ id: "", status: false }))
            return;
        } else if (cookieLoginStatus.loginInfo.status == true) {
            dispatch(setLoginStatus({ id: cookieLoginStatus.loginInfo.id, status: true }))
        }
    }

    const onKeyPressEnter = (e) => {
        if (e.key == "Enter") {
            onClickLoingBtn();
        }
    }

    // useEffect(() => {
    useLayoutEffect(() => {
        checkRememberCheck()
    }, [loginStatus])

    // useEffect(() => {
    useLayoutEffect(() => {
        checkCookies()
    }, [cookies]);




    return (
        // <div>
        //     <input placeholder='id' type={"text"} onChange={(e) => { setId(e.target.value) }} value={id} /> <br />
        //     <input placeholder='password' type={"password"} onChange={(e) => { setPassword(e.target.value) }} value={password} /> <br />
        //     <input type={"checkbox"} value={rememberCheck} onClick={onClickRememberCheck} />Remember me<br />
        //     <button onClick={onClickLoingBtn}>
        //         Login
        //     </button>
        // </div>
        <div>
            <div className={loginStyles.loginWrap}>
                <div>
                    <img src={require("../asset/img/common_logo_symbol@2x.png")} width={"93"} alt="MMT Admin 로고" />
                    <h2 className={loginStyles.tit} >MMT Admin</h2>
                    <div className={loginStyles.iptWrap} >
                        <input type={"text"} onChange={(e) => { setId(e.target.value) }} value={id} className={`${loginStyles.iptNormal} ${loginStyles.iptId}`} placeholder="ID" onKeyPress={onKeyPressEnter} />
                        <input type={"password"} onChange={(e) => { setPassword(e.target.value) }} value={password} className={loginStyles.iptNormal} placeholder="Password" onKeyPress={onKeyPressEnter} />
                    </div>
                    <div className={loginStyles.rememberWrap} >
                        <label className={loginStyles.chkLabelWrap} >
                            <input type={"checkbox"} value={rememberCheck} onClick={onClickRememberCheck} />
                            <span className={loginStyles.chkLabel} ></span>
                            <span className={loginStyles.chkTxt} >Remember me</span>
                        </label>
                    </div>
                    <button type="button" className={loginStyles.btnLogin} onClick={onClickLoingBtn} onKeyPress={onKeyPressEnter}>Login</button>
                </div>
            </div>

        </div>
    )
}

export default Login