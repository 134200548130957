import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { setLoginStatus } from '../redux_modules/user';

import rightBoxTopStyles from "../asset/css/Common.module.css";
import axios from 'axios';

const RightBoxTop = ({popup, setPopup, onClickConnectBtn, detailMenuType, account, onClickDisConnect, onClickMetaMask, removeCookie, active }) => {

  const dispatch = useDispatch();
  const [reAccount, setReAccount] = useState('');

  const accountReNumber = () => {
    if (account != undefined) {
      const startStr = account.substring(0, 3);
      const endStr = account.substring(account.length, account.length - 3);

      setReAccount(startStr + '...' + endStr);
    }
  };

  const logout = () => {
    try {
      removeCookie("loginStatus");
      window.localStorage.setItem("MammothAdmin", false);
      onClickDisConnect();
      dispatch(setLoginStatus({id:"",status:false}))
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    if (account != "") {
      accountReNumber();
    }
  }, [account]);


  return (
    <div className={rightBoxTopStyles.top}>
      <button type={"button"} className={rightBoxTopStyles.btnGrey} onClick={logout}>로그아웃</button>
      {active ?
        <div className={rightBoxTopStyles.myWallet} onClick={onClickDisConnect} style={{cursor:"pointer"}}>{reAccount}</div>
        :
        <div className={rightBoxTopStyles.myWallet} onClick={onClickConnectBtn} style={{cursor:"pointer"}}>Connect Wallet</div>
      }
      {popup ?
        <div id={rightBoxTopStyles.connectWalletPop} className={rightBoxTopStyles.popupWrap}>
          <div className={rightBoxTopStyles.popupDim} onClick={()=>{setPopup(false)}}></div>
          <div className={rightBoxTopStyles.popupContainer} >
            <div className={rightBoxTopStyles.title} >Connect Wallet</div>
            <div className={rightBoxTopStyles.connectList}>
              <div className={rightBoxTopStyles.mb} onClick={onClickMetaMask}>
                <img src={require("../asset/img/sym_metamask@2x.png")} width={"40"} alt="metamask" />
                MetaMask
              </div>
            </div>
          </div>
        </div>
        : ""}
    </div>
  )
}

export default RightBoxTop