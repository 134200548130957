import React, { useEffect, useState } from 'react';

import MMTStakingJSON from "../abi/MMTStaking.json";
import { BN } from 'bn.js';

import poolStyle from "../asset/css/Common.module.css";
import { fromWei, toWei } from '../common/common';
import { useDispatch } from 'react-redux';
import { changeOwner, playAction, setLockedBlock } from '../redux_modules/mmt';


const MMTStaking_mmt = ({ userId, setMenuType, setDetailMenuType, account, active, web3, defaultProviderWeb3 }) => {

    const dispatch = useDispatch();

    const contractAddress = process.env.REACT_APP_STAKING_CONTRACTADDRESS;

    const [contract, setContract] = useState();

    const [functionStatus, setFunctionStatus] = useState("");
    const [popupStatus, setPopupStatus] = useState(false);
    const [pageType, setPageType] = useState("mmtManagement")
    const [popupType, setPopupType] = useState("");

    const [inputValue, setInputValue] = useState("");
    const [inputAddress, setInputAddress] = useState("");

    const [owner, setOwner] = useState("");
    const [lockupDate, setLockupDate] = useState("0");
    const [lockupType, setLockupType] = useState("");
    const [lockManagement, setLockManagement] = useState("");
    const [selectValue, setSelectValue] = useState("days");
    const [loading, setLoading] = useState(false);
    const [transferOwner, setTransferOwner] = useState("")
    const [tx, setTx] = useState("")
    const [errorMessage, setErrorMessage] = useState(false)

    const setContractMethods = () => {
        if (defaultProviderWeb3 != undefined) {
            let contract = new defaultProviderWeb3.eth.Contract(MMTStakingJSON, contractAddress);
            setContract(contract)
        }
    }

    const settingOwner = async () => {
        if (contract != undefined) {
            let owner = await contract.methods.owner().call();
            setOwner(owner.toLowerCase());
        }
    }

    const getLockupDate = async () => {
        if (contract != undefined && owner != "") {
            let lockupBlock = await contract.methods.getLOCKED_BLOCK().call({
                from: owner
            });

            let locktime = new BN(`${lockupBlock}`).mul(new BN(`${3}`)).toString();

            if (Number(locktime) >= 0 && Number(locktime) < 3600) {
                locktime = new BN(`${locktime}`).div(new BN(`${60}`)).toString();
                setLockupType("Minutes")
            } else if (Number(locktime) >= 3600 && Number(locktime) < 86400) {
                locktime = new BN(`${locktime}`).div(new BN(`${3600}`)).toString();
                setLockupType("Hours")

            } else if (Number(locktime) >= 86400) {
                locktime = new BN(`${locktime}`).div(new BN(`${86400}`)).toString();
                setLockupType("Days")
            }

            setLockupDate(locktime)
        }
    }

    const onClickPopupType = (e, type) => {
        e.preventDefault();
        if (account == "") {
            window.alert('지갑 연결 하시오');
        } else if (inputValue == "") {
            window.alert('수량을 입력하시오');
        } else if (functionStatus == "withdraw" && inputAddress.length != 42) {
            window.alert('주소를 입력하시오');
        } else {
            setPopupStatus(true);
            setPopupType(type);
        }
    }

    const onChangeInputValue = (e) => {
        let amount = e.target.value;
        let pattern = /^[0-9]*[.]?[0-9]*$/;
        if (pattern.test(amount) == true) {
            let index = amount.indexOf(".");
            if (index != -1) {
                let decimalLength = amount.substr((index + 1), amount.length);
                if (decimalLength.length <= 18) {
                    setInputValue(amount);
                }
            } else {
                setInputValue(amount);
            }
        }
    }


    const onClickPlay = async () => {
        if (account == "") {
            window.alert("지갑 연결해유")
        } else if (owner.toLowerCase().includes(account)) {
            let value = toWei(web3, inputValue);
            let contract = new web3.eth.Contract(MMTStakingJSON, contractAddress);
            let result
            setLoading(true);
            try {
                if (functionStatus == "deposit") {
                    result = await contract.methods.depositMMT().send({
                        from: account,
                        value: value
                    })
                } else if (functionStatus == 'withdraw') {
                    result = await contract.methods.WithdrawMMT(inputAddress, value).send({
                        from: account
                    })
                }
                if (result.status) {
                    setInputAddress("")
                    setInputValue("")
                    setPopupStatus(false)
                    let data ={
                        address:functionStatus == "deposit"?account.toLowerCase():inputAddress.toLowerCase(),
                        howMuch:inputValue,
                    }
                    dispatch(playAction({
                        data,
                        userId,
                        type:functionStatus
                    }))
                }

            } catch (error) {
                setLoading(false);

            }
        } else {
            window.alert("owner 아니유~")
        }
    }

    const onCheckAddress = (e) => {
        let address = e.target.value;
        setInputAddress(address)
    }

    const onChangeLockupManagement = (value) => {
        let pattern = /^\d{0,10}$/;
        if(pattern.test(value)){
            setLockManagement(value)
        }
    }

    const onClickLockTimePopup = async () => {
        let result
        if (account == "") {
            window.alert("지갑 연결");
        } else if (owner.toLowerCase() != account.toLowerCase()) {
            window.alert("owner 아님")
        } else if (lockManagement == "") {
            window.alert("기간 입력하시오")
        } else {
            setPopupStatus(true)
            setPopupType("lockup")
        }
    }

    const onClickLockTime = async () => {
        let result
        let lockTime;
        // 분으로 변환
        if (selectValue == "minutes") {
            lockTime = new BN(`${lockManagement}`).toString();
        } else if (selectValue == "hours") {
            lockTime = new BN(`${lockManagement}`).mul(new BN(`${60}`)).toString();

        } else if (selectValue == "days") {
            lockTime = new BN(`${lockManagement}`).mul(new BN(`${1440}`)).toString();
        }

        let contract = new web3.eth.Contract(MMTStakingJSON, contractAddress);
        setLoading(true)
        try {
            result = await contract.methods.setLockedBlock(lockTime).send({
                from: account
            });

            if (result.status) {
                setPopupStatus(false)
                setTx(result.transactionHash);
                setLoading(false);
                dispatch(setLockedBlock({
                    userId:userId,
                    time:lockManagement,
                    type:selectValue,
                }))
                setLockManagement("")
            }

        } catch (error) {
            setLoading(false)

        }
    }

    const onClickOwnerPopup = () => {
        console.log(transferOwner.length != 42 && !transferOwner.includes("0x"))
        if (account == "") {
            window.alert("지갑 연결");
        } else if (owner.toLowerCase() != account.toLowerCase()) {
            window.alert("owner 아님")
        } else if (transferOwner == "") {
            window.alert("지갑 주소를 입력하시오")
        } else if (!transferOwner.includes("0x")) {
            window.alert("정확한 지갑 주소를 입력하시오")
        
        } else if (transferOwner.length != 42 ) {
            window.alert("정확한 지갑 주소를 입력하시오(42자리)")}
        else {
            setPopupStatus(true)
            setPopupType("owner")
        }
    }

    const onClickTransferOwner = async () => {
        let contract = new web3.eth.Contract(MMTStakingJSON, contractAddress);
        let result;
        setLoading(true);
        let existingOwner = owner;
        try {
            result = await contract.methods.transferOwnership(transferOwner).send({
                from: account
            });

            if (result.status) {
                setPopupStatus(false)
                setTx(result.transactionHash)
                setLoading(false)
                setTransferOwner("");
                dispatch(changeOwner({
                    userId : userId,
                    existingOwner:existingOwner.toLowerCase(),
                    newOwner:transferOwner.toLowerCase()
                }))
            }

        } catch (error) {
            console.log("error",error)
            setLoading(false)

        }
    }


    useEffect(() => {
        setContractMethods();
    }, [defaultProviderWeb3]);

    useEffect(() => {
        setMenuType("mmtstaking");
        setDetailMenuType("mmt");
    }, []);

    useEffect(() => {
        settingOwner();
    }, [contract,tx]);

    useEffect(() => {
        getLockupDate();
    }, [contract, owner, tx])

    return (
        <div className={poolStyle.rightBox}>
            <div className={poolStyle.content}>
                <h3 className={poolStyle.tit}>MMT 관리</h3>
                <div className={`${poolStyle.mmtTab}`} >
                    <button type="button" className={`${pageType == "mmtManagement" ? poolStyle.on : ""} ${poolStyle.btnMmt}`} onClick={() => {
                        setPageType("mmtManagement");
                        setLockManagement("")
                    }}>MMT 입/출금 관리</button>
                    <button type="button" className={`${pageType == "lockUpManagement" ? poolStyle.on : ""} ${poolStyle.btnLockup}`} onClick={() => {
                        setPageType("lockUpManagement");
                        setFunctionStatus("")
                        setLockManagement("")
                    }}>Lock-up 기간 관리</button>
                </div>

                <div id={pageType == "mmtManagement" ? poolStyle.mmtWrap : pageType == "lockUpManagement" ? poolStyle.lockupWrap : ""}>
                    {
                        pageType == "mmtManagement" ?
                            <div className={`${poolStyle.wrap} ${poolStyle.mmtWrap} ${poolStyle.contract}`}>
                                <div className={poolStyle.tit} >MMT Contract</div>
                                <div className={poolStyle.mmtAddress} >{contractAddress}</div>
                                <div className={poolStyle.fr} >
                                    <button type="button" className={poolStyle.btnBlack} onClick={() => {
                                        setFunctionStatus("deposit")
                                        setInputValue("");
                                    }}>입금</button>
                                    <button type="button" className={poolStyle.btnGreen} onClick={() => {
                                        setFunctionStatus("withdraw");
                                        setInputAddress("")
                                        setInputValue("");
                                    }}>출금</button>
                                    <button type="button" className={poolStyle.btnGrey} onClick={() => {
                                        setFunctionStatus("owner")
                                        setTransferOwner("")
                                    }}>Owner변경</button>
                                </div>
                            </div>
                            : pageType == "lockUpManagement" ?
                                <div id={poolStyle.lockupWrap} >
                                    {/* <div className={`${poolStyle.wrap} ${poolStyle.searchWrap} ${poolStyle.faq}`}>
                                    <div className={`${poolStyle.tit} `} >Pool 검색</div>
                                    <div>
                                        <input type="text" className={`${poolStyle.iptNormal} ${poolStyle.v2}`} placeholder="지갑주소 입력" />
                                    </div>
                                    <button type="button" className={`${poolStyle.btnGrey} ${poolStyle.btnReset}`} >초기화</button>
                                    <button type="button" className={`${poolStyle.btnBlack} ${poolStyle.btnSearch}`} >조회</button>
                                </div> */}

                                    <div id={poolStyle.lockupDateWrap} className={`${poolStyle.wrap} ${poolStyle.mmtWrap}`} >
                                        <div className={`${poolStyle.tit} `} >Lock-up 기간 관리</div>
                                        <div className={`${poolStyle.iptWrap} ${poolStyle.fr}`} >
                                            <div className={`${poolStyle.date} `} >
                                                <span className={`${poolStyle.iptTit}`} >현재 기간</span>
                                                <span className={`${poolStyle.fwb} `} >{lockupDate} {lockupType}</span>
                                            </div>
                                            <div>
                                                <span className={`${poolStyle.iptTit}`} >변경 할 기간</span>
                                                <input type="text" className={`${poolStyle.iptNormal} ${poolStyle.date}`} placeholder="소수점 입력X" value={lockManagement} onChange={(e) => onChangeLockupManagement(e.target.value)} />
                                                <select className={`${poolStyle.boldTxt} `} onChange={(e) => { setSelectValue(e.target.value) }} value={selectValue}>
                                                    <option value={"minutes"}>Minutes</option>
                                                    <option value={"hours"}>Hours</option>
                                                    <option value={"days"}>Days</option>
                                                </select>
                                            </div>
                                            <button type="button" className={`${poolStyle.btnGreen} `} onClick={onClickLockTimePopup}>실행</button>
                                        </div>
                                    </div>
                                </div>
                                : ""
                    }
                    {
                        functionStatus == "deposit" ?
                            <div id={poolStyle.depositWrap} className={`${poolStyle.wrap} ${poolStyle.mmtWrap}`} >
                                <div className={poolStyle.tit} >MMT 입금</div>
                                <div className={poolStyle.fr} >
                                    <span className={poolStyle.iptTit}>입금 할 수량</span>
                                    <input type="text" className={`${poolStyle.iptNormal} ${poolStyle.v2}`} placeholder='소수점 18자리 초과 X' required value={inputValue} onChange={(e) => { onChangeInputValue(e) }} />
                                    <span className={poolStyle.boldTxt}>MMT</span>
                                    <button type="button" className={poolStyle.btnGreen} onClick={(e) => { onClickPopupType(e, "deposit") }}>실행</button>
                                </div>
                            </div>

                            : functionStatus == "withdraw" ?

                                <div id={poolStyle.withdrawWrap} className={`${poolStyle.wrap} ${poolStyle.mmtWrap}`}>
                                    <div className={poolStyle.tit}>MMT 출금</div>
                                    <div className={`${poolStyle.iptWrap} ${poolStyle.fr}`}>
                                        <div className={poolStyle.address} >
                                            <span className={poolStyle.iptTit}>받을 주소</span>
                                            <input type="text" className={`${poolStyle.iptNormal} ${poolStyle.v2}`} placeholder="0x...." value={inputAddress} onChange={((e) => { onCheckAddress(e) })} />
                                        </div>
                                        <div>
                                            <span className={poolStyle.iptTit} >출금 할 수량</span>
                                            <input type="text" className={`${poolStyle.iptNormal} ${poolStyle.v2}`} placeholder='소수점 18자리 초과 X' required value={inputValue} onChange={(e) => { onChangeInputValue(e) }} />
                                            <span className={poolStyle.boldTxt} >MMT</span>
                                        </div>
                                        <button type="button" className={poolStyle.btnGreen} onClick={(e) => { onClickPopupType(e, "withdraw") }}>실행</button>
                                    </div>
                                </div>
                                : functionStatus == "owner" ?
                                    <div id={poolStyle.ownerWrap} className={`${poolStyle.wrap} ${poolStyle.mmtWrap}`} >
                                        <div className={poolStyle.tit} >MMT Contract Owner 관리</div>
                                        <div className={`${poolStyle.iptWrap} ${poolStyle.fr}`} >
                                            <div className={`${poolStyle.address} ${poolStyle.owner}`} >
                                                <span className={poolStyle.iptTit} >현재 Owner</span>
                                                <span className={poolStyle.fwb} >{owner}</span>
                                            </div>
                                            <div>
                                                <span className={poolStyle.iptTit} >수정 할 Owner</span>
                                                <input type="text" className={`${poolStyle.iptNormal} ${poolStyle.v2}`} placeholder="0x... 지갑 주소 입력" value={transferOwner} onChange={(e)=>{setTransferOwner(e.target.value)}}/>
                                            </div>
                                            <button type="button" className={poolStyle.btnGreen} onClick={onClickOwnerPopup}>실행</button>
                                        </div>
                                        <div className={poolStyle.contractMsg} >※ Contract 수정은 신중히 해주십시오.</div>
                                    </div>
                                    : ""
                    }
                </div>
            </div>

            {
                popupStatus ?
                    popupType == "deposit" ?
                        <div id={poolStyle.depositPop} className={poolStyle.popOuter} >
                            <div className={poolStyle.popInner} >
                                <img src={require("../asset/img/common_ico_deposit.png")} />
                                <p className={poolStyle.msg}>
                                    <span className={poolStyle.blue} >{inputValue} MMT</span>를 <span className={poolStyle.bold} >입금</span>하시겠습니까?{errorMessage?"(Error)":""}
                                </p>
                                {loading ?
                                    <div className={poolStyle.btnWrap}>
                                        <button className={poolStyle.btnGrey}>실행중</button>
                                    </div>
                                    :
                                    <div className={poolStyle.btnWrap}>
                                        <button type="button" className={poolStyle.btnGrey} onClick={() => { setPopupStatus(false) }}>취소</button>
                                        <button type="button" className={poolStyle.btnGreen} onClick={onClickPlay}>입금</button>
                                    </div>
                                }
                            </div>
                        </div>
                        : popupType == "withdraw" ?
                            <div id={poolStyle.withdrawPop} className={poolStyle.popOuter} >
                                <div className={poolStyle.popInner}>
                                    <img src={require("../asset/img/common_ico_withdraw.png")} />
                                    <p className={poolStyle.msg} >
                                        <span className={poolStyle.blue} >{inputValue} MMT</span>를<br />
                                        <span className={poolStyle.grey} >{contractAddress}</span> 에서<br />
                                        <span className={poolStyle.bold} >출금</span> 하시겠습니까?{errorMessage?"(Error)":""}
                                    </p>
                                    {loading ?
                                        <div className={poolStyle.btnWrap}>
                                            <button className={poolStyle.btnGrey}>실행중</button>
                                        </div>
                                        :
                                        <div className={poolStyle.btnWrap}>
                                            <button type="button" className={poolStyle.btnGrey} onClick={() => { setPopupStatus(false) }}>취소</button>
                                            <button type="button" className={poolStyle.btnBlack} onClick={onClickPlay}>출금</button>
                                        </div>
                                    }
                                </div>
                            </div>
                            : popupType == "lockup" ?
                                <div id={poolStyle.lockupPop} className={poolStyle.popOuter}>
                                    <div className={poolStyle.popInner} >
                                        <img src={require("../asset/img/common_pop_ico_warning.png")} />
                                        <p className={poolStyle.msg} >Lock-up 기간을<br />변경하시겠습니까?</p>
                                        {loading ?
                                            <div className={poolStyle.btnWrap}>
                                                <button className={poolStyle.btnGrey}>실행중</button>
                                            </div>
                                            :
                                            <div className={poolStyle.btnWrap} >
                                                <button type="button" className={poolStyle.btnGrey} onClick={() => { setPopupStatus(false) }}>취소</button>
                                                <button type="button" className={poolStyle.btnBlack} onClick={onClickLockTime}>실행</button>
                                            </div>
                                        }
                                    </div>
                                </div>
                                : popupType == "owner" ?
                                    <div id={poolStyle.lockupPop} className={poolStyle.popOuter} >
                                        <div className={poolStyle.popInner} >
                                            <img src={require("../asset/img/common_pop_ico_warning.png")} />
                                            <p className={poolStyle.msg} >Contract Owner를<br />변경하시겠습니까?</p>
                                            {loading ?
                                                <div className={poolStyle.btnWrap}>
                                                    <button className={poolStyle.btnGrey}>실행중</button>
                                                </div>
                                                :
                                                <div className={poolStyle.btnWrap} >
                                                    <button type="button" className={poolStyle.btnGrey} onClick={() => { setPopupStatus(false) }}>취소</button>
                                                    <button type="button" className={poolStyle.btnBlack} onClick={onClickTransferOwner}>실행</button>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    : ""
                    : ""
            }

        </div>
    )
}

export default MMTStaking_mmt