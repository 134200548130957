import React, { useEffect, useState } from 'react';
import poolStyle from "../asset/css/Common.module.css";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/esm/locale";

import "../asset/css/reactDatePicker.css"
import DatePicker from "react-datepicker";
import moment from 'moment';
import { useSelector } from 'react-redux';

const IvorySwapNotice = ({ setMenuType, setDetailMenuType, account, active, web3, defaultProviderWeb3 }) => {

  const noticeList = useSelector((state)=>state.ivory.noticeList)
  const [viewNoticeList, setViewNoticeList] = useState([1]);
  const [pageType, setPageType] = useState("noticeList");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [limit, setLimit] = useState(10);
  const [listView, setListView] = useState(false);
  const [detailNotice, setDetailNotice] = useState({
    no:"",
    title:"",
    description:"",
    exposureType:true,
  })


  const setNoticeLists = () => {
    
  }

  const NoticeList = () => {
    return (
      viewNoticeList==""?"":
        viewNoticeList.map((item, index) => (
          <tr key={index}>
            <td>{item.no}</td>
            <td>
              <p className={`${poolStyle.name}`} >{item.title}</p>
            </td>
            <td>{item.writer}</td>
            <td>{item.createDate}</td>
            <td><div href="#" className={`${poolStyle.management}`} style={{cursor:"pointer"}} onClick={()=>{onClickUpdate(item)}}>수정/삭제</div></td>
          </tr>
        ))
    )
  }

  const onClickListLimit = (num) => {
    setLimit(num);
    setListView(false);
  }

  const onClickUpdate = (item) => {
    setPageType("noticeDetail")
    setDetailNotice(item)
  }
  

  useEffect(() => {
    setMenuType("ivoryswap");
    setDetailMenuType("notice");
  }, []);

  return (
    <div className={`${poolStyle.rightBox}`} >
      <div className={`${poolStyle.content}`} >
        <h3 className={`${poolStyle.tit}`} >공지사항 관리</h3>
        {pageType == "noticeList" ?
          <div className={`${poolStyle.wrap} ${poolStyle.searchWrap}`} >
            <div className={`${poolStyle.tit}`}>검색</div>
            <div>
              <input type="text" className={`${poolStyle.iptNormal} ${poolStyle.iptWriter}`} placeholder="작성자 입력" />
            </div>
            <div className={`${poolStyle.dateWrap}`} >
              <div>
                <DatePicker
                  locale={ko}
                  buttonImageOnly={true}
                  dateFormat="YYYY.MM.DD HH:mm"
                  className={`${poolStyle.iptNormal} ${poolStyle.dateSearch}`}
                  value={startDate}
                  showTimeSelect
                  onChange={(date) => {
                    setStartDate(moment(date).format("YYYY.MM.DD HH:mm"))
                  }} />
                {/* <input type="text" className={`${poolStyle.iptNormal} ${poolStyle.dateSearch}`} /> */}
              </div>
              <span>~</span>
              <div>
                <DatePicker
                  locale={ko}
                  buttonImageOnly={true}
                  dateFormat="YYYY.MM.DD HH:mm"
                  className={`${poolStyle.iptNormal} ${poolStyle.dateSearch2}`}
                  value={endDate}
                  showTimeSelect
                  onChange={(date) => {
                    setEndDate(moment(date).format("YYYY.MM.DD HH:mm"))
                  }} />
                {/* <input type="text" className={`${poolStyle.iptNormal} ${poolStyle.dateSearch2}`} /> */}
              </div>
            </div>
            <div className={`${poolStyle.btnWrap}`} >
              <button type="button" className={`${poolStyle.btnGrey} ${poolStyle.btnReset}`}
                onClick={()=>{
                  setStartDate("")
                  setEndDate("")
                }}
              >초기화</button>
              <button type="button" className={`${poolStyle.btnBlack} ${poolStyle.btnSearch}`} >조회</button>
            </div>
          </div>
          : ""}
        {pageType == "noticeList" ?
          <div className={`${poolStyle.wrap} ${poolStyle.tableWrap}`} >
            <div className={`${poolStyle.numberWrap}`} >
              <p className={`${poolStyle.allInfo}`} >총 328건</p>
              <div className={`${poolStyle.selectNormal}`} >
                <div className={`${poolStyle.selected}`} onClick={()=>{setListView(!listView)}}>{limit}개씩 보기</div>
                {
                  listView ?
                    <div className={`${poolStyle.optionList}`} >
                      <ul>
                        <li onClick={()=>{onClickListLimit(10)}}>10개씩 보기</li>
                        <li onClick={()=>{onClickListLimit(15)}}>15개씩 보기</li>
                        <li onClick={()=>{onClickListLimit(20)}}>20개씩 보기</li>
                      </ul>
                    </div>
                    : ""
                }
              </div>
            </div>
            <table className={`${poolStyle.tableNormal}`} >
              <colgroup>
                <col width="106" />
                <col width="*" />
                <col width="150" />
                <col width="150" />
                <col width="116" />
              </colgroup>
              <thead>
                <tr>
                  <th>NO</th>
                  <th>제목</th>
                  <th>작성자</th>
                  <th>작성일</th>
                  <th>관리</th>
                </tr>
              </thead>
              <tbody>
                {/* <tr>
                  <td>328</td>
                  <td>
                    <p className={`${poolStyle.name}`} >제목 영역입니다.</p>
                  </td>
                  <td>Arumy</td>
                  <td>2022.08.25 15:06</td>
                  <td><a href="#" className={`${poolStyle.management}`}>수정/삭제</a></td>
                </tr> */}
                <NoticeList/>
              </tbody>
            </table>
            <div className={`${poolStyle.paging}`}>
              <ul>
                <li className={`${poolStyle.prev}`} ><a href="#">prev</a></li>
                <li className={`${poolStyle.on}`} ><a href="#">1</a></li>
                <li><a href="#">2</a></li>
                <li><a href="#">3</a></li>
                <li><a href="#">4</a></li>
                <li><a href="#">5</a></li>
                <li><a href="#">6</a></li>
                <li><a href="#">7</a></li>
                <li><a href="#">8</a></li>
                <li><a href="#">9</a></li>
                <li><a href="#">10</a></li>
                <li className={`${poolStyle.next}`}><a href="#">next</a></li>
              </ul>
            </div>
            <button className={`${poolStyle.btnGreen} ${poolStyle.btnWrite}`} >
              <div onClick={()=>{setPageType("noticeDetail")}}>글쓰기</div>
            </button>
          </div>
          : pageType == "noticeDetail" ?
            <div className={`${poolStyle.wrap} ${poolStyle.tableWrap}`} >
              <div className={`${poolStyle.tit}`} >공지사항 글쓰기</div>
              <table className={`${poolStyle.tableNormal} ${poolStyle.write}`}>
                <colgroup>
                  <col width="140" />
                  <col width="*" />
                </colgroup>
                <tbody>
                  <tr>
                    <th>제목</th>
                    <td><input type="text" className={`${poolStyle.iptNormal}`} value={detailNotice.title} placeholder={"제목 입력"} 
                    onChange={(e)=>{
                      setDetailNotice((prevData)=>({
                      ...prevData,
                      title:e.target.value
                    }))}}/></td>
                  </tr>
                  <tr>
                    <th className={`${poolStyle.alignTop}`} >내용</th>
                    <td>
                      <textarea className={`${poolStyle.textareaNormal}`}  value={detailNotice.description} placeholder={"내용 입력"} 
                      onChange={(e)=>{
                        setDetailNotice((prevData)=>({
                        ...prevData,
                        description:e.target.value
                      }))
                      }}></textarea>
                    </td>
                  </tr>
                  <tr className={`${poolStyle.view}`}>
                    <th>노출여부</th>
                    <td>
                      <label htmlFor="show" onClick={()=>{
                        setDetailNotice((preData)=>({
                          ...preData,
                          exposureType:true
                        }))
                      }}>
                        <input type="radio" id={poolStyle.show} name="viewType" checked={detailNotice.exposureType?true:false} onChange={""}/>
                          <span className={`${poolStyle.radioLabel}`}></span>
                          <span className={`${poolStyle.radioTxt}`}>화면 노출</span>
                      </label>
                      <label htmlFor="hide"onClick={()=>{
                        setDetailNotice((preData)=>({
                          ...preData,
                          exposureType:false
                        }))
                      }}>
                        <input type="radio" id={poolStyle.hide} name="viewType"  checked={detailNotice.exposureType?false:true} onChange={""}/>
                        <span className={`${poolStyle.radioLabel}`}></span>
                          <span className={`${poolStyle.radioTxt}`}>화면 미노출</span>
                      </label>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className={`${poolStyle.btnWrap} ${poolStyle.write}`} >
                <button type="button" className={`${poolStyle.btnGrey} ${poolStyle.btnCancel}`}>
                  <div onClick={()=>setPageType("noticeList")}>취소</div>
                </button>
                <button type="button" className={`${poolStyle.btnGreen} ${poolStyle.btnRegister}`} >등록</button>
              </div>
            </div>
            : ""}
      </div>
    </div>
  )
}

export default IvorySwapNotice