import { createAction, handleAction, handleActions } from 'redux-actions';
import { put, takeEvery, takeLatest, call } from 'redux-saga/effects';
import produce from 'immer';
import axios from 'axios';

const initialState = {
    operatorList: [],
}


const SETOPERATORLIST = "operator/SETOPERATORLIST";
export const setOperatorList = createAction(SETOPERATORLIST, (input) => input);

const WAITOPERATORLIST = "operator/WAITOPERATORLIST";
export const waitOperatorLists = createAction(WAITOPERATORLIST, (input) => input);

export function* waitOperatorList() {
    yield takeEvery(WAITOPERATORLIST, getOperatorList);
}

function* getOperatorList() {
    const initData = yield call([axios, "get"], "/mmtstaking/OperatorList",);
    if (initData.status) {
        yield put(setOperatorList(initData.data))
    }
}

const SETOPERATORSTATUS = "operator/SETOPERATORSTATUS";
export const setOperatorStatus = createAction(SETOPERATORSTATUS, (input) => input);

export function* waitSetOperatorStatus() {
    yield takeEvery(SETOPERATORSTATUS, dbSetOperatorStatus);
}

function* dbSetOperatorStatus(body) {
    let initData;
    let { NO, address, bool, nickname } = body.payload.updateOperator;
    if (body.payload.changeStatus == true) {
        if (bool == 0) {
            initData = yield call([axios, "post"], "/mmtstaking/appointOperator", {
                add: address,
                nickname: nickname,
                id: body.payload.userId
            });
        } else if (bool == 1) {
            initData = yield call([axios, "post"], "/mmtstaking/dismissOperator", {
                add: address,
                nickname: nickname,
                id: body.payload.userId
            });
        }
        if (initData.status) {
            yield getOperatorList();
        }

    } else if (body.payload.changeStatus == false) {
        initData = yield call([axios, "post"], "/mmtstaking/setOperatorNickname", {
            add: address,
            nickname: nickname,
            id: body.payload.userId
        });
        if (initData.status) {
            yield getOperatorList();
        }
    }
}


const operator = handleActions({
    [SETOPERATORLIST]: (state, { payload: input }) =>
        produce(state, (draft) => {
            draft.operatorList = input;
        }),
}, initialState);

export default operator;