import React, { useEffect } from 'react';

import mainStyles from "../asset/css/Common.module.css";

const Main = ({ }) => {

  return (
    <div className={mainStyles.rightBox}>
      <div className={mainStyles.content}>
        <h3 className={mainStyles.tit}>Dashboard</h3>
        <div className={mainStyles.dashboard}>
          대시보드 영역<br />
          (TBD)
        </div>
      </div>
    </div>
  )
}

export default Main