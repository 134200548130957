export const list = [
    "0x3Ae51bcd9fDcd5ae35CDC4e0f786d61D26Ce56c3",
    "0xa231c415C805ECa669d30442229810A9e7908c00",
    "0x9Ee385865eb91ee82b7C656d252E4605993fBace",
    "0xFfa0c87Bc1c81Bc830Bd394939f12b552Ff3f280",
    "0x7494dCF3C6FEAdcA45A36BDe5B81498938083d4B",
    "0x6887c4716f9A9d183402a40Cf714ae5b06E86F20",
    "0xe5c069e892B997a641019f40868ba937A34Ef139",
    "0xc5446A38A92A89B78a5E63A32A2db9Eb7704bc87",
    "0x5f978ECECDF2B584704257670eFc2c69502536Ee",
    "0xa6D537Cb9aB12b89efE9ba2befCf3691B3fDeEdC",
    "0x5a3cA11E0AadEB4C693Bb7d82FADfB3888A44b7F",
    "0xDb02739a1dDe9A05C4Aa401E811362f798c1768f",
    "0x8DD5FA021AcAC08b41E749137431e8F8Af6Dc9Fe",
    "0xc752c7f223843DF2B73F67Bd465ccaB3C8A2Fa0e",
    "0xDfb53bFF3D6b1d9649A7b2Ef98809BAdf459a2d9",
    "0x81a70C1589534725d4702A0b6D22F2B7F1A3e6de",
    "0x896A1D3b300b43e64eb1f5132be29eFd9578c6B4",
    "0x2Dc38392979EF152b48D82afB300cc0F687bba02",
    "0xCd75Eb7171fEfB047E7811709FeA5DFeca189320",
    "0xa78A940E106503c198CAC266492e3AD0E150798D",
    "0x93Ea472dB52700107e401f676A4a9dC3b0e3469d",
    "0xD2E5D04b598Ea4440b9202516D0c87C488E0A8Fe"
]