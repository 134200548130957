import React, { useState } from 'react';
import { useEffect } from 'react';

import poolStyle from "../asset/css/Common.module.css";

const IvorySwapFAQ = ({ userId, setMenuType, setDetailMenuType, account, active, web3, defaultProviderWeb3 }) => {

  const [mainPageType, setMainPageType] = useState("faq");
  const [pageType, setPageType] = useState("faqList");
  const [mainSelectStatus, setMainSelectStatus] = useState(false);
  const [mainSelectValue, setMainSelectValue] = useState("전체 목록");
  const [categoryList , setCategoryList] = useState();
  const [viewCategoryList, setViewCategoryList] = useState([1]);
  
  const CategoryList = () => {
    return (viewCategoryList == "" ? "" :
    viewCategoryList.map((item, index) => (
      <tr>
        <td>
          <label className={`${poolStyle.chkLabelWrap}`}  >
            <input type="checkbox" />
            <span className={`${poolStyle.chkLabel}`}  ></span>
          </label>
        </td>
        <td>Farm</td>
        <td>2022.08.25 15:06</td>
      </tr>
    )))
  }

  const CategoryCreateUpdate = () => {
    return (
      viewCategoryList == "" ? "" :
        viewCategoryList.map((item, index) => (
          <tr>
            <td>
              <label className={`${poolStyle.chkLabelWrap}`} >
                <input type="checkbox" />
                <span className={`${poolStyle.chkLabel}`}></span>
              </label>
            </td>
            <td>
              <div className={`${poolStyle.categoryValue}`}  >Farm</div>
              {item.status ?
                <div className={`${poolStyle.modifyWrap}`} >
                  <input type="text" className={`${poolStyle.iptNormal} ${poolStyle.modify}`} value="Farm" />
                </div>
                : ""}
            </td>
            <td>
              <button type="button" className={`${poolStyle.management}`}>수정</button>
            </td>
          </tr>
        ))
    )
  }

  const onClickMainSelectValue = (value) => {
    setMainSelectValue(value);
    setMainSelectStatus(false);
  }


  useEffect(() => {
    setMenuType("ivoryswap");
    setDetailMenuType("faq");
  }, []);

  return (
    <div className={`${poolStyle.rightBox}`}>
      {
        mainPageType == "faq" ?
          <div className={`${poolStyle.content}`} >
            <h3 className={`${poolStyle.tit}`} >FAQ 관리</h3>
            {pageType == "faqList" ?
              <div className={`${poolStyle.wrap} ${poolStyle.searchWrap} ${poolStyle.faq}`}>
                <div className={`${poolStyle.tit}`}>조회</div>
                <div>
                  <div className={`${poolStyle.selectNormal} ${poolStyle.faq}`} >
                    <div className={`${poolStyle.selected}`} onClick={() => setMainSelectStatus(!mainSelectStatus)}>{mainSelectValue}</div>
                    {mainSelectStatus ?
                      <div className={`${poolStyle.optionList}`} >
                        <ul>
                          <li onClick={() => { onClickMainSelectValue("전체 목록") }}>전체 목록</li>
                          <li onClick={() => { onClickMainSelectValue("카테고리1") }}>카테고리1</li>
                          <li onClick={() => { onClickMainSelectValue("카테고리2") }}>카테고리2</li>
                        </ul>
                      </div>
                      : ""}
                  </div>
                  <button type="button" className={`${poolStyle.btnBlack} ${poolStyle.btnSearch}`} >조회</button>
                </div>
                <button type="button" className={`${poolStyle.btnGrey} ${poolStyle.fr}`} >
                  <div onClick={() => {
                    setMainPageType("categoryManagement")
                    setPageType("categoryList")
                  }}>카테고리 관리</div>
                </button>
              </div>
              : ""}
            {pageType == "faqList" ?
              <div className={`${poolStyle.wrap} ${poolStyle.tableWrap}`} >
                <div className={`${poolStyle.numberWrap}`}>
                  <p className={`${poolStyle.allInfo}`}>총 328건</p>
                </div>
                <table className={`${poolStyle.tableNormal}`}>
                  <colgroup>
                    <col width="56" />
                    <col width="106" />
                    <col width="*" />
                    <col width="150" />
                    <col width="150" />
                    <col width="116" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>
                        <label className={`${poolStyle.chkLabelWrap}`} >
                          <input type="checkbox" />
                          <span className={`${poolStyle.chkLabel}`} ></span>
                        </label>
                      </th>
                      <th>NO</th>
                      <th>제목</th>
                      <th>작성자</th>
                      <th>작성일</th>
                      <th>관리</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <label className={`${poolStyle.chkLabelWrap}`} >
                          <input type="checkbox" />
                          <span className={`${poolStyle.chkLabel}`}></span>
                        </label>
                      </td>
                      <td>328</td>
                      <td>
                        <p className={`${poolStyle.name}`}>제목 영역입니다.</p>
                      </td>
                      <td>Arumy</td>
                      <td>2022.08.25 15:06</td>
                      <td><a href="#" className={`${poolStyle.management}`}>수정/삭제</a></td>
                    </tr>
                  </tbody>
                </table>
                <div className={`${poolStyle.paging}`} >
                  <ul>
                    <li className={`${poolStyle.prev}`} ><a href="#">prev</a></li>
                    <li className={`${poolStyle.on}`}><a href="#">1</a></li>
                    <li><a href="#">2</a></li>
                    <li><a href="#">3</a></li>
                    <li><a href="#">4</a></li>
                    <li><a href="#">5</a></li>
                    <li><a href="#">6</a></li>
                    <li><a href="#">7</a></li>
                    <li><a href="#">8</a></li>
                    <li><a href="#">9</a></li>
                    <li><a href="#">10</a></li>
                    <li className={`${poolStyle.next}`} ><a href="#">next</a></li>
                  </ul>
                </div>
                <button className={`${poolStyle.btnGreen} ${poolStyle.btnWrite}`}>
                  <div onClick={() => { setPageType("faqDetail") }}>글쓰기</div>
                </button>
              </div>
              : pageType == "faqDetail" ?
                <div className={`${poolStyle.wrap} ${poolStyle.tableWrap}`} >
                  <div className={`${poolStyle.tit}`} >FAQ 글쓰기</div>
                  <table className={`${poolStyle.tableNormal} ${poolStyle.write}`} >
                    <colgroup>
                      <col width="140" />
                      <col width="*" />
                    </colgroup>
                    <tbody>
                      <tr className={`${poolStyle.view}`} >
                        <th>카테고리</th>
                        <td>
                          <div className={`${poolStyle.selectNormal} ${poolStyle.faq}`}>
                            <div className={`${poolStyle.selected}`}>선택</div>
                            <div className={`${poolStyle.optionList}`} >
                              <ul>
                                <li>카테고리1</li>
                                <li>카테고리2</li>
                              </ul>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>제목</th>
                        <td><input type="text" className={`${poolStyle.iptNormal}`} placeholder="제목 입력" /></td>
                      </tr>
                      <tr>
                        <th className={`${poolStyle.alignTop}`} >내용</th>
                        <td>
                          <textarea className={`${poolStyle.textareaNormal}`} placeholder="내용 입력"></textarea>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className={`${poolStyle.btnWrap} ${poolStyle.write}`}>
                    <button type="button" className={`${poolStyle.btnGrey} ${poolStyle.btnCancel}`} >
                      <div onClick={() => { setPageType("faqList") }}>취소</div>
                    </button>
                    <button type="button" className={`${poolStyle.btnGreen} ${poolStyle.btnRegister}`} >등록</button>
                  </div>
                </div>
                : ""}
          </div>
          : mainPageType == "categoryManagement" ?
            <div className={`${poolStyle.content}`}>
              <h3 className={`${poolStyle.tit}`}>FAQ 관리</h3>
              <div className={`${poolStyle.wrap} ${poolStyle.searchWrap} ${poolStyle.faq}`}>
                <div className={`${poolStyle.tit}`}>카테고리 관리</div>
                {
                  pageType == "categoryList" ?
                    <button type="button" className={`${poolStyle.btnGreen} ${poolStyle.fr}`} >
                      <div onClick={() => { setPageType("categoryCreateUpdate") }}>등록/수정</div>
                    </button>
                    : pageType == "categoryCreateUpdate" ?
                      <div className={`${poolStyle.fr}`}>
                        <input type="text" className={`${poolStyle.iptNormal} ${poolStyle.v2}`} placeholder="카테고리명 입력"/>
                          <button type="button" className={`${poolStyle.btnGreen}`} >등록</button>
                      </div>
                    : ""
                }
              </div>
              <div className={`${poolStyle.wrap} ${poolStyle.tableWrap}`} >
                <div className={`${poolStyle.tit}`}  >카테고리 리스트</div>
                <table className={`${poolStyle.tableNormal}`} >
                  <colgroup>
                    <col width="56" />
                    <col width="*" />
                    <col width="150" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>
                        <label className={`${poolStyle.chkLabelWrap}`}  >
                          <input type="checkbox" />
                          <span className={`${poolStyle.chkLabel}`}   ></span>
                        </label>
                      </th>
                      <th>카테고리</th>
                      <th>{pageType == "categoryList" ? "등록일" : "수정"}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      pageType == "categoryList" ?
                        <CategoryList />
                        : pageType == "categoryCreateUpdate" ?
                          <CategoryCreateUpdate />
                          : ""
                    }
                  </tbody>
                </table>
                <button className={`${poolStyle.btnGrey} ${poolStyle.btnDelete}`} >선택삭제</button>
                <div className={`${poolStyle.paging}`}>
                  <ul>
                    <li className={`${poolStyle.prev}`}><a href="#">prev</a></li>
                    <li className={`${poolStyle.on}`} ><a href="#">1</a></li>
                    <li><a href="#">2</a></li>
                    <li><a href="#">3</a></li>
                    <li><a href="#">4</a></li>
                    <li><a href="#">5</a></li>
                    <li><a href="#">6</a></li>
                    <li><a href="#">7</a></li>
                    <li><a href="#">8</a></li>
                    <li><a href="#">9</a></li>
                    <li><a href="#">10</a></li>
                    <li className={`${poolStyle.next}`} ><a href="#">next</a></li>
                  </ul>
                </div>
              </div>
            </div>
            : ""}
    </div>
  )
}

export default IvorySwapFAQ