import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Web3 from 'web3';
import AppCSS from "./App.css"

import Menu from './containers/Menu';
import { list } from './list';
import IvorySwapFAQ from './Page/IvorySwapFAQ';
import IvorySwapNotice from './Page/IvorySwapNotice';
import Login from './Page/Login';
import Main from './Page/Main';
import MMTStaking_mmt from './Page/MMTStaking_mmt';
import MMTStaking_operator from './Page/MMTStaking_operator';
import MMTStaking_pool from './Page/MMTStaking_pool';
import { setAccount, setActive, setChainId } from './redux_modules/user';

import MMTStakingJSON from "./abi/MMTStaking.json"
import EDEM from './Page/EDEM';
import RightBoxTop from './containers/RightBoxTop';

function App() {

  const dispatch = useDispatch()

  const [cookies, setCookie, removeCookie] = useCookies(['loginStatus']);

  const loginStatus = useSelector((state) => state.user.loginStatus);
  const account = useSelector((state) => state.user.account);
  const active = useSelector((state) => state.user.active);
  const chainId = useSelector((state) => state.user.chainId);
  const userId = useSelector((state) => state.user.userId);

  const defaultRPC = process.env.REACT_APP_NETWORK_RPC;
  const defaultChainId = process.env.REACT_APP_NETWORK_CHAINID;

  const [connectBtn, setConnectBtn] = useState(false);
  const [web3, setWeb3] = useState();
  const [web3Provider, setWeb3Provider] = useState();
  const [defaultProviderWeb3, setDefaultProviderWeb3] = useState();

  const [popup, setPopup] = useState(false)

  
  const [menuType, setMenuType] = useState("dashboard");
  const [detailMenuType, setDetailMenuType] = useState("");

  const onClickConnectBtn = () => {
    setPopup(true)
  }

  const onClickMetaMask = async () => {
    if (window.ethereum != undefined) {
      try {
        const web3MetaMask = new Web3(window.ethereum);
        const accounts = await window.ethereum.send("eth_requestAccounts");
        const chainNum = await web3MetaMask.eth.getChainId();
        if (String(chainNum) == chainId) {
          setWeb3(web3MetaMask);
          setWeb3Provider(window.ethereum);
          dispatch(setAccount(accounts.result[0]));
          dispatch(setActive(true));
          let timestamp = Math.floor(+ new Date() / 1000);
          let obj = {
            value: "inject",
            expire: timestamp + 3600
          }
          let getObjString = window.localStorage.getItem("MammothAdmin_MetaMask");
          if (!getObjString) {
            const objString = JSON.stringify(obj)
            window.localStorage.setItem("MammothAdmin_MetaMask", objString);
          }
          setPopup(false)
        } else if (String(chainNum) != chainId) {
          onChangeChainId(window.ethereum)
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      window.open(`https://metamask.app.link/dapp/${process.env.REACT_APP_URL}`);
    }
  }

  const onChangeChainId = async (web3Provider) => {
    let netWorkVersion;
    let _rpcUrl;
    let blockExplorerURL;
    let chainName;
    let symbol;

    try {

      if (chainId == "88998") {
        // testnet
        netWorkVersion = "0x15ba6";
        _rpcUrl = "https://data-seed-premmt-1.mmtscan.io";
        blockExplorerURL = "https://testnet.mmtscan.io";
        chainName = "Mammoth TestNet";
        symbol = "MMT";
      } else if (chainId == "8898") {
        //  mainnet
        netWorkVersion = "0x22c2";
        _rpcUrl = "https://dataseed.mmtscan.io";
        blockExplorerURL = "https://mmtscan.io";
        chainName = "Mammoth Pro"
        symbol = "MMT";
      };

      await web3Provider.request({
        method: 'wallet_switchEthereumChain',
        params: [
          {
            chainId: netWorkVersion,
          },
        ],
      });

    } catch (error) {
      try {
        await web3Provider.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: netWorkVersion,
              chainName: chainName,
              rpcUrls: [_rpcUrl],
              nativeCurrency: {
                "name": chainName,
                "symbol": symbol,
                "decimals": 18
              },
              blockExplorerUrls: [blockExplorerURL]
            },
          ],
        });

      } catch (addError) {
      }
    }
  }

  if (window.ethereum != undefined) {
    window.ethereum.on("accountsChanged", async function (accountsed) {
      if (account != accountsed[0]) {
        onClickDisConnect();
      }
    });

    window.ethereum.on("chainChanged", async function (chainIds) {
      onClickDisConnect();
    });
  }

  const checkInject = async () => {
    let objString = window.localStorage.getItem("MammothAdmin_MetaMask");
    try {

      if(!objString){
        await onClickDisConnect();
      }

      if (objString) {
        const obj = JSON.parse(objString);
        let timestamp =  Math.floor(+ new Date() / 1000);
        if (timestamp > obj.expire) {
          window.localStorage.removeItem("MammothAdmin_MetaMask");
        } else {
          await onClickMetaMask();
        }
      }

    } catch (error) {
      console.log(error)
    }
  }


  const onClickDisConnect = async () => {
    dispatch(setAccount(""));
    dispatch(setActive(false));
    window.localStorage.removeItem("MammothAdmin_MetaMask");
  }

  const onClickLi = (mainType, detailType) => {
    setMenuType(mainType)
    setDetailMenuType(detailType)
  }

  useEffect(() => {
    dispatch(setChainId(defaultChainId));
  }, [])

  useEffect(()=>{
    let web3 = new Web3(new Web3.providers.HttpProvider(defaultRPC));
    setDefaultProviderWeb3(web3)
  },[])

  // useEffect(()=>{
  useLayoutEffect(()=>{
    checkInject();
  },[chainId]);



  return (
    <BrowserRouter>
      {loginStatus ? <RightBoxTop popup={popup} setPopup={setPopup} onClickConnectBtn={onClickConnectBtn} detailMenuType={detailMenuType}removeCookie={removeCookie} account={account} active={active} onClickMetaMask={onClickMetaMask} onClickDisConnect={onClickDisConnect} /> : ""}
      {loginStatus ? <Menu onClickLi={onClickLi} detailMenuType={detailMenuType} setDetailMenuType={setDetailMenuType} menuType={menuType} setMenuType={setMenuType} /> : ""}
      <Routes>
        <Route path="/" element={loginStatus ? <Main setMenuType={setMenuType}/> : <Login cookies={cookies} loginStatus={loginStatus} />} />
        <Route path="/mmtStaking/pool" element={loginStatus ? <MMTStaking_pool userId={userId} setDetailMenuType={setDetailMenuType} setMenuType={setMenuType} account={account} active={active} web3={web3} web3Provider={web3Provider} defaultProviderWeb3={defaultProviderWeb3}/> : <Login cookies={cookies} loginStatus={loginStatus} />} />
        <Route path="/mmtStaking/mmt" element={loginStatus ? <MMTStaking_mmt userId={userId} setDetailMenuType={setDetailMenuType} setMenuType={setMenuType} account={account} active={active} web3={web3} web3Provider={web3Provider} defaultProviderWeb3={defaultProviderWeb3}/> : <Login cookies={cookies} loginStatus={loginStatus}/>} />
        <Route path="/mmtStaking/operator" element={loginStatus ? <MMTStaking_operator userId={userId} setDetailMenuType={setDetailMenuType} setMenuType={setMenuType} account={account} active={active} web3={web3} web3Provider={web3Provider} defaultProviderWeb3={defaultProviderWeb3}/> : <Login cookies={cookies} loginStatus={loginStatus}/>} />
        <Route path="/ivoryswap/notice" element={loginStatus ? <IvorySwapNotice setDetailMenuType={setDetailMenuType} setMenuType={setMenuType} account={account} active={active} web3={web3} web3Provider={web3Provider} defaultProviderWeb3={defaultProviderWeb3} /> : <Login cookies={cookies} loginStatus={loginStatus}/>} />
        <Route path="/ivoryswap/faq" element={loginStatus ? <IvorySwapFAQ setDetailMenuType={setDetailMenuType} setMenuType={setMenuType} account={account} active={active} web3={web3} web3Provider={web3Provider} defaultProviderWeb3={defaultProviderWeb3}/> : <Login cookies={cookies} loginStatus={loginStatus} />} />
        <Route path="/ivoryswap/edem" element={loginStatus ? <EDEM /> : <Login cookies={cookies} loginStatus={loginStatus} />} />
      </Routes>
    </BrowserRouter>
  );

}


export default App;
